import { Avatar, SelectInput, TextInput } from "components/core";
import { DistrictPicker } from "containers";
import { FormikProps } from "formik";
import lodash from "lodash";
import { classNames } from "utils";

type Props = {
  customer: any;
  form: FormikProps<any>;
};

const UpdateServiceRequestCustomerForm: React.FC<Props> = ({
  customer,
  form,
}) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200 ">
      <div>
        <div className="">
          <span className="text-xs font-light">Customer Information</span>
        </div>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="row-span-3 flex flex-col space-y-3 items-center justify-center">
            <Avatar
              alt={
                customer?.customer?.customerType === "Individual"
                  ? customer?.customer?.representative?.fullName || "N A"
                  : customer?.customer?.organization?.name || "N A"
              }
              src={customer?.customer?.profileImageUrl || ""}
              size="xl"
            />

            <span
              className={classNames(
                "bg-gray-100 text-gray-800",
                customer?.status === "SuperAdmin"
                  ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                  : "",
                customer?.status === "Admin"
                  ? `bg-gray-100 text-[#657383] dark:bg-[#657383] dark:text-gray-50`
                  : "",
                customer?.status === "Supervisor"
                  ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                  : "",
                customer?.status === "Reader"
                  ? `bg-gray-100 text-[#9ACD32] dark:bg-[#9ACD32] dark:text-gray-50`
                  : "",
                customer?.status === "Queued"
                  ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                  : "",
                customer?.status === "Success"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                customer?.status === "Reversed"
                  ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                  : "",
                !customer?.status
                  ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                  : "",
                "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1",
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                fill="currentColor"
                className="w-1.5 h-1.5"
              >
                <circle
                  fillRule="evenodd"
                  cx="5"
                  cy="5"
                  r="5"
                  clipRule="evenodd"
                />
              </svg>
              <span>
                {lodash.startCase(customer?.status) || "Active Contract"}
              </span>
            </span>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Request Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Ghana Card Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.ghanaCardNumber || "N/A"
                : customer?.customer?.organization
                    ?.organizationRegistrationNumber || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.fullName
                : customer?.customer?.organization?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.phoneNumber || "N/A"
                : customer?.customer?.organization?.phoneNumber || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Email Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {customer?.customer?.customerType === "Individual"
                ? customer?.customer?.representative?.emailAddress || "N/A"
                : customer?.customer?.organization?.emailAddress || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-6 divide-y divide-gray-200 pt-6 flex-1">
        {/* {JSON.stringify(form.values, null, 2)} */}
        <div>
          <span className="text-xs font-light">Customer Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div className="">
              <TextInput
                id="fullName"
                label="Full Name"
                type="text"
                placeholder="e.g. Mensah Enoch Nana Nyankah"
                {...form}
              />
            </div>
            <div>
              <TextInput
                id="phoneNumber"
                label="Phone Number"
                type="text"
                placeholder="e.g. 0550123292"
                {...form}
              />
            </div>
            <div className="">
              <SelectInput
                id="serviceType"
                label="Service Type"
                options={[
                  {
                    label: "select one",
                    value: "",
                  },
                  {
                    label: "Prepaid",
                    value: "Prepaid",
                  },
                  {
                    label: "Postpaid",
                    value: "Postpaid",
                  },
                ]}
                {...form}
              />
            </div>
            <div className="">
              <SelectInput
                id="serviceClass"
                label="Service Class"
                options={[
                  {
                    label: "select one",
                    value: "",
                  },
                  {
                    label: "Residential",
                    value: "Residential",
                  },
                  {
                    label: "Non-Residential",
                    value: "NonResidential",
                  },
                  {
                    label: "Industrial",
                    value: "Industrial",
                  },
                ]}
                {...form}
              />
            </div>
            <div>
              <DistrictPicker
                disabled
                label="District"
                rawId
                id="district"
                {...form}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateServiceRequestCustomerForm;
