export const Titles = [
  "Mr",
  "Mrs",
  "Miss",
  "Madam",
  "Rev",
  "Prof",
  "Col",
  "MrAndMrs",
] as const;
export type Title = (typeof Titles)[number];

export default Titles;
