import { FC } from "react";
import { wrapClick } from "utils";
import { gql, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { UserForm, Modal } from "components";

const CREATE_CONTRACTOR_USER = gql`
  mutation CreatePartnerUser(
    $lastName: String!
    $firstName: String!
    $ghanaCardNumber: String
    $phoneNumber: String!
    $emailAddress: String
    $profileImageUrl: String
    $role: PartnerUserRole!
  ) {
    createPartnerUser(
      lastName: $lastName
      firstName: $firstName
      ghanaCardNumber: $ghanaCardNumber
      phoneNumber: $phoneNumber
      emailAddress: $emailAddress
      profileImageUrl: $profileImageUrl
      role: $role
    ) {
      _id
    }
  }
`;

interface CreateUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const CreateUserContainer: FC<CreateUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const [createUser, { loading }] = useMutation(CREATE_CONTRACTOR_USER);

  const form = useFormik<any>({
    initialValues: {
      code: "",
      lastName: "",
      firstName: "",
      ghanaCardNumber: "",
      phoneNumber: "",
      emailAddress: "",
      profileImageUrl: "",
    },
    onSubmit: async (values) => {
      await createUser({
        variables: {
          ...values,
        },
      }).then(({ data }) => {
        if (data.createPartnerUser._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "User Created Successfully",
            }),
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({ type: "error", title: "Could not create User" }),
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title="Add New User"
      description="Provide the details to add a new user"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Adding User..." : "Add User"}
          </button>
        </>
      )}
    >
      <UserForm form={form} />
    </Modal>
  );
};

export default CreateUserContainer;
