import { CustomerType, IdentityCardType, IdentityCardTypes } from "apollo/data";
import { validators } from "utils";
import * as Yup from "yup";

export interface IdentityCardInfoForm {
  hasGhanaCard: boolean;
  ghanaCardNumber: string;
  ghanaCardIssueDate: string;
  ghanaCardExpiryDate: string;
  ghanaCardFrontImageUrl: string;
  ghanaCardBackImageUrl: string;
  identityCardType: IdentityCardType;
  identityCardNumber: string;
  identityCardIssueDate: string;
  identityCardExpiryDate: string;
  identityCardFrontImageUrl: string;
  identityCardBackImageUrl: string;
}

export interface IIdentityInfoFormSchema {
  representative: IdentityCardInfoForm;
  organization: {
    taxIdentificationNumber?: string;
    organizationRegistrationNumber?: string;
    organizationRegistrationDate?: string;
    organizationRegistrationDocumentUrl?: string;
    certificateOfIncorporationDocumentUrl?: string;
  };
}

export const IdentityCardInfoFormSchema = Yup.object().shape({
  hasGhanaCard: Yup.boolean().required(),
  ghanaCardNumber: Yup.string()
    .matches(
      validators.GhanaCardRegex,
      "Kindly enter a valid Ghana Card Number",
    )
    .when("hasGhanaCard", {
      is: true,
      then: (schema) => schema.required("Ghana Card Number is required"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  ghanaCardIssueDate: Yup.date().when("hasGhanaCard", {
    is: true,
    then: (schema) => schema.required("Ghana Card issue date is required"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  ghanaCardExpiryDate: Yup.date().when("hasGhanaCard", {
    is: true,
    then: (schema) => schema.required("Ghana Card expiry date is required"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  ghanaCardFrontImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: true,
      then: (schema) =>
        schema.required("Kindly attach front image of Ghana Card"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  ghanaCardBackImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: true,
      then: (schema) =>
        schema.required("Kindly attach rear image of Ghana Card"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  identityCardType: Yup.string().when("hasGhanaCard", {
    is: false,
    then: (schema) =>
      schema
        .oneOf([...IdentityCardTypes])
        .required("Kindly select ID Card type"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  identityCardNumber: Yup.string().when("hasGhanaCard", {
    is: false,
    then: (schema) => schema.required("Kindly enter ID Card number"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  identityCardIssueDate: Yup.date().when("hasGhanaCard", {
    is: false,
    then: (schema) => schema.required("Kindly enter ID Card issue date"),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  identityCardExpiryDate: Yup.date().when("hasGhanaCard", {
    is: false,
    then: (schema) =>
      schema.when("identityCardType", {
        is: "VotersIdentificationCard",
        then: (schema) => schema.nullable().notRequired(),
        otherwise: (schema) =>
          schema.required("Kindly enter ID Card expiry date"),
      }),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  identityCardFrontImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: false,
      then: (schema) => schema.required("Kindly attach front image of ID Card"),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  identityCardBackImageUrl: Yup.string()
    .url()
    .when("hasGhanaCard", {
      is: false,
      then: (schema) =>
        schema.when("identityCardType", {
          is: "DriversLicense",
          then: (schema) =>
            schema.required("Kindly attach rear image of ID Card"),
          otherwise: (schema) => schema.nullable().notRequired(),
        }),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
});

export const IdentityInfoFormSchema = (
  customerType: CustomerType,
  isPublicOrg = true,
) =>
  Yup.object().shape({
    representative: IdentityCardInfoFormSchema,
    organization:
      customerType === "Organization" && !isPublicOrg
        ? Yup.object().shape({
            taxIdentificationNumber: Yup.string()
              .matches(
                /^(C|P|R|G)(\d|[A-Z]){10}$/,
                "Enter a valid Tax Identification Number",
              )
              .required("Tax Identification is required"),
            organizationRegistrationNumber: Yup.string()
              .matches(/^[0-9A-Z]{11}$/, "Enter a valid registration number")
              // .length(11, "Registration Number is not")
              .required("Registration number is required"),
            organizationRegistrationDate: Yup.date()
              .max(new Date())
              .required("Registration date is required"),
            organizationRegistrationDocumentUrl: Yup.string()
              .url()
              .required("Registration Document Is Required"),
            certificateOfIncorporationDocumentUrl: Yup.string()
              .url()
              .required("Certificate Of Incorporation Is Required"),
          })
        : Yup.object()
            .shape({
              taxIdentificationNumber: Yup.string().nullable().notRequired(),
              organizationRegistrationNumber: Yup.string()
                .nullable()
                .notRequired(),
              organizationRegistrationDate: Yup.date().nullable().notRequired(),
              organizationRegistrationDocumentUrl: Yup.string()
                .nullable()
                .notRequired(),
              certificateOfIncorporationDocumentUrl: Yup.string()
                .nullable()
                .notRequired(),
            })
            .nullable(),
  });
