import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import lodash from "lodash";
import { MapProvider, Marker } from "react-map-gl";
import Map from "react-map-gl";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { wrapImage } from "utils";
import config from "config";


interface ServiceRequestViewProps {
  serviceRequest: {
    code: string;
    category: string;
    request: {
      customer: any;
      property: any;
      service: any;
    };
    status: string;
    type: string;
    createdAt: string;
    updatedAt: string;
  };
}

export const ServiceRequestView: FC<ServiceRequestViewProps> = ({
  serviceRequest,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Service Request Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about service request
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="">
              <span className="text-xs font-light">
                Service Request Information
              </span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Request Code
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.code || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Request Date
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {moment(serviceRequest?.createdAt).format(dateFormat)}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Request Category
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {lodash.startCase(serviceRequest?.category) || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Request Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {lodash.startCase(serviceRequest?.type) || "N/A"}
                  </div>
                </div>
                <div className="">
                  <span className="block text-sm font-light text-gray-700">
                    Service Request Status
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {lodash.startCase(serviceRequest?.status) || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Customer Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about customer</p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="pt-6">
              <span className="text-xs font-light">User Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Customer Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.customer?.customerType || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            {serviceRequest?.request?.customer?.customerType ===
              "Individual" && (
              <div className="pt-6">
                <span className="text-xs font-light">Customer Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="col-span-1">
                    <span className="block text-sm font-light text-gray-700">
                      Photo
                    </span>
                    <Avatar
                      alt={
                        serviceRequest?.request?.customer?.representative
                          .fullName || "N A"
                      }
                      src={
                        serviceRequest?.request?.customer?.representative
                          .profileImageUrl || ""
                      }
                      size="lg"
                    />
                  </div>
                  <div className="col-start-1">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.title || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Full Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Date of Birth
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {moment(
                        serviceRequest?.request?.customer?.representative
                          ?.dateOfBirth,
                      ).format(dateFormat)}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Gender
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.gender || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {serviceRequest?.request?.customer?.customerType ===
              "Organization" && (
              <div className="pt-6">
                <span className="text-xs font-light">
                  Organization Information
                </span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.organization?.name ||
                        "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {serviceRequest?.request?.customer?.customerType ===
              "Individual" && (
              <div className="pt-6">
                <span className="text-xs font-light">Contact Information</span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Phone Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.phoneNumber || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Email Address
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.emailAddress || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {serviceRequest?.request?.customer?.customerType ===
              "Organization" && (
              <div className="pt-6">
                <span className="text-xs font-light">
                  Representative Information
                </span>
                <div className="grid grid-cols-3 gap-6 mt-2">
                  <div className="col-start-1">
                    <span className="block text-sm font-light text-gray-700">
                      Title
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.title || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Full Name
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.fullName || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Nationality
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.nationality || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Phone Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.phoneNumber || "N/A"}
                    </div>
                  </div>
                  <div className="">
                    <span className="block text-sm font-light text-gray-700">
                      Email Address
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.representative
                        ?.emailAddress || "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Customer Identity Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about identity of customer
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            {serviceRequest?.request?.customer?.customerType ===
              "Individual" && (
              <>
                <div className="pt-6">
                  <span className="text-xs font-light">
                    Customer Identity Information
                  </span>
                  <div className="grid grid-cols-6 gap-6 mt-2">
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Has Ghana Card
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {serviceRequest?.request?.customer?.representative
                          ?.hasGhanaCard
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                    {!serviceRequest?.request?.customer?.representative
                      ?.hasGhanaCard && (
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Type
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {lodash.startCase(
                            serviceRequest?.request?.customer?.representative
                              ?.identityCardType,
                          ) || "N/A"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {serviceRequest?.request?.customer?.representative
                  ?.hasGhanaCard ? (
                  <div className="pt-6">
                    <span className="text-xs font-light">
                      Customer Identity Information
                    </span>
                    <div className="grid grid-cols-6 gap-6 mt-2">
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {serviceRequest?.request?.customer?.representative
                            ?.ghanaCardNumber || "N/A"}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Issue Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.ghanaCardIssueDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Expiry Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.ghanaCardExpiryDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Front Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.ghanaCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />,
                          )}
                        </div>
                      </div>
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Back Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.ghanaCardBackImageUrl
                              }
                              alt={"back"}
                              className="w-full h-64 text-xs"
                            />,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pt-6">
                    <span className="text-xs font-light">
                      Customer Identity Information
                    </span>
                    <div className="grid grid-cols-6 gap-6 mt-2">
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {serviceRequest?.request?.customer?.representative
                            ?.identityCardNumber || "N/A"}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Issue Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.identityCardIssueDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      {serviceRequest?.request?.customer?.representative
                        ?.identityCardType !== "VotersIdentificationCard" && (
                        <div className="col-span-2">
                          <span className="block text-sm font-light text-gray-700">
                            ID Expiry Date
                          </span>
                          <div className="mt-1 block w-full sm:text-sm">
                            {moment(
                              serviceRequest?.request?.customer?.representative
                                ?.identityCardExpiryDate,
                            ).format(dateFormat)}
                          </div>
                        </div>
                      )}
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          ID Front Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.identityCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />,
                          )}
                        </div>
                      </div>
                      {serviceRequest?.request?.customer?.representative
                        ?.identityCardType === "DriversLicense" && (
                        <div className="col-span-3">
                          <span className="block text-sm font-light text-gray-700">
                            ID Back Image
                          </span>
                          <div className="mt-2">
                            {wrapImage(
                              <img
                                src={
                                  serviceRequest?.request?.customer
                                    ?.representative?.identityCardBackImageUrl
                                }
                                alt={"back"}
                                className="w-full h-64 text-xs"
                              />,
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {serviceRequest?.request?.customer?.customerType ===
              "Organization" && (
              <div className="pt-6">
                <span className="text-xs font-light">
                  Organization Identity Information
                </span>
                <div className="grid grid-cols-6 gap-6 mt-2">
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Tax Identification Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.organization
                        ?.taxIdentificationNumber || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Organization Registration Number
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {serviceRequest?.request?.customer?.organization
                        ?.organizationRegistrationNumber || "N/A"}
                    </div>
                  </div>
                  <div className="col-span-2">
                    <span className="block text-sm font-light text-gray-700">
                      Organization Registration Date
                    </span>
                    <div className="mt-1 block w-full sm:text-sm">
                      {moment(
                        serviceRequest?.request?.customer?.organization
                          ?.organizationRegistrationDate,
                      ).format(dateFormat)}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Certificate of Incorporation
                    </span>
                    <div className="mt-2">
                      {wrapImage(
                        <img
                          src={
                            serviceRequest?.request?.customer?.organization
                              ?.certificateOfIncorporationDocumentUrl
                          }
                          alt={"Certificate of Incorporation"}
                          className="w-full h-64 text-xs"
                        />,
                      )}
                    </div>
                  </div>
                  <div className="col-span-3">
                    <span className="block text-sm font-light text-gray-700">
                      Business Regsitration Document
                    </span>
                    <div className="mt-2">
                      {wrapImage(
                        <img
                          src={
                            serviceRequest?.request?.customer?.organization
                              ?.organizationRegistrationDocumentUrl
                          }
                          alt={"Business Registration Document"}
                          className="w-full h-64 text-xs"
                        />,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {serviceRequest?.request?.customer?.customerType ===
              "Organization" && (
              <>
                <div className="pt-6">
                  <span className="text-xs font-light">
                    Representative Identity Information
                  </span>
                  <div className="grid grid-cols-6 gap-6 mt-2">
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Has Ghana Card
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {serviceRequest?.request?.customer?.representative
                          ?.hasGhanaCard
                          ? "Yes"
                          : "No"}
                      </div>
                    </div>
                    {!serviceRequest?.request?.customer?.representative
                      ?.hasGhanaCard && (
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Type
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {lodash.startCase(
                            serviceRequest?.request?.customer?.representative
                              ?.identityCardType,
                          ) || "N/A"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {serviceRequest?.request?.customer?.representative
                  ?.hasGhanaCard ? (
                  <div className="pt-6">
                    <span className="text-xs font-light">
                      Representative Identity Information
                    </span>
                    <div className="grid grid-cols-6 gap-6 mt-2">
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {serviceRequest?.request?.customer?.representative
                            ?.ghanaCardNumber || "N/A"}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Issue Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.ghanaCardIssueDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Expiry Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.ghanaCardExpiryDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Front Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.ghanaCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />,
                          )}
                        </div>
                      </div>
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          Ghana Card Back Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.ghanaCardBackImageUrl
                              }
                              alt={"back"}
                              className="w-full h-64 text-xs"
                            />,
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="pt-6">
                    <span className="text-xs font-light">
                      Representative Identity Information
                    </span>
                    <div className="grid grid-cols-6 gap-6 mt-2">
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Number
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {serviceRequest?.request?.customer?.representative
                            ?.identityCardNumber || "N/A"}
                        </div>
                      </div>
                      <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                          ID Issue Date
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                          {moment(
                            serviceRequest?.request?.customer?.representative
                              ?.identityCardIssueDate,
                          ).format(dateFormat)}
                        </div>
                      </div>
                      {serviceRequest?.request?.customer?.representative
                        ?.identityCardType !== "VotersIdentificationCard" && (
                        <div className="col-span-2">
                          <span className="block text-sm font-light text-gray-700">
                            ID Expiry Date
                          </span>
                          <div className="mt-1 block w-full sm:text-sm">
                            {moment(
                              serviceRequest?.request?.customer?.representative
                                ?.identityCardExpiryDate,
                            ).format(dateFormat)}
                          </div>
                        </div>
                      )}
                      <div className="col-span-3">
                        <span className="block text-sm font-light text-gray-700">
                          ID Front Image
                        </span>
                        <div className="mt-2">
                          {wrapImage(
                            <img
                              src={
                                serviceRequest?.request?.customer
                                  ?.representative?.identityCardFrontImageUrl
                              }
                              alt={"front"}
                              className="w-full h-64 text-xs"
                            />
                            // ,
                            // !serviceRequest?.request?.customer?.representative
                            //   ?.identityCardFrontImageUrl,
                          )}
                        </div>
                      </div>
                      {serviceRequest?.request?.customer?.representative
                        ?.identityCardType === "DriversLicense" && (
                        <div className="col-span-3">
                          <span className="block text-sm font-light text-gray-700">
                            ID Back Image
                          </span>
                          <div className="mt-2">
                            {wrapImage(
                              <img
                                src={
                                  serviceRequest?.request?.customer
                                    ?.representative?.identityCardBackImageUrl
                                }
                                alt={"back"}
                                className="w-full h-64 text-xs"
                              />,
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Property Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">Details about property</p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="pt-6">
              <span className="text-xs font-light">Owner Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Full name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.owner?.fullName ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Phone Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.owner?.phoneNumber ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">Property Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Region
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.region?.name || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    District
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.district?.name || "N/A"}
                  </div>
                </div>
                {/* <div>
                  <span className="block text-sm font-light text-gray-700">
                    Structure Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.structureNumber || "N/A"}
                  </div>
                </div> */}
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Ghana Post Address
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.ghanaPostAddress ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Street Name
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.streetName || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    House Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.houseNumber || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Community
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.community || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Closest Landmark
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.landmark || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">Premise Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Premise Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.premiseType?.name ||
                      "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Premise Category
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.premiseCategory?.name ||
                      "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Activity
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.activity?.name || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Sub Activity
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.property?.subActivity?.name ||
                      "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6 grid grid-cols-2 gap-6">
              <div>
                <span className="text-xs font-light">
                  Site Plan Information
                </span>
                {serviceRequest?.request?.property?.sitePlanDocumentUrl ? (
                  <div className="mt-2">
                    {wrapImage(
                      <img
                        src={
                          serviceRequest?.request?.property?.sitePlanDocumentUrl
                        }
                        alt={serviceRequest?.request?.property?.structureNumber}
                        className="w-full h-64 text-xs"
                      />,
                    )}
                  </div>
                ) : (
                  <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
                    Site plan not provided
                  </div>
                )}
              </div>
              <div>
                <span className="text-xs font-light">Map Information</span>
                <div className="mt-2 w-full h-64 rounded-md overflow-hidden">
                  <MapProvider>
                    <Map
                      id="service-location"
                      initialViewState={{
                        longitude:
                          serviceRequest?.request?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361,
                        latitude:
                          serviceRequest?.request?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032,
                        zoom: 15,
                      }}
                      mapStyle="mapbox://styles/mapbox/streets-v12"
                      mapboxAccessToken={config.mapbox.token}
                    >
                      <Marker
                        longitude={
                          serviceRequest?.request?.property?.geoLocation
                            ?.coordinates?.[0] || -0.234361
                        }
                        latitude={
                          serviceRequest?.request?.property?.geoLocation
                            ?.coordinates?.[1] || 5.667032
                        }
                      >
                        <img
                          className="w-6 h-6"
                          src={
                            "/logo.png"
                          }
                          alt={"pin"}
                        />
                      </Marker>
                    </Map>
                  </MapProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <div className="flex justify-between items-center cursor-pointer">
          <div>
            <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
              Service Information
            </h3>
            <p className="mt-1 text-xs text-gray-500">
              Details about service being requested for
            </p>
          </div>
          <div />
        </div>
        <div className="mt-4">
          <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
            <div className="pt-6">
              <span className="text-xs font-light">Service Information</span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Service Type
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.service?.serviceType || "N/A"}
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Service Class
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.service?.serviceClass
                      ? lodash.startCase(
                          serviceRequest?.request?.service.serviceClass,
                        )
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-6">
              <span className="text-xs font-light">
                Energy Certification Information
              </span>
              <div className="grid grid-cols-3 gap-6 mt-2">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Energy Certificate Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {serviceRequest?.request?.service
                      ?.energyCertificateNumber || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Energy Certificate Document
                  </span>
                  <div className="mt-1 block sm:text-sm">
                    {wrapImage(
                      <img
                        src={
                          serviceRequest?.request?.service
                            ?.energyCertificateDocumentUrl
                        }
                        alt={
                          serviceRequest?.request?.service
                            ?.energyCertificateNumber
                        }
                        className="w-full  h-64 object-cover object-top text-xs"
                      />,
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestView;
