import { FC } from "react";
import numeral from "numeral";

const MaterialsInfoCard: FC<{
  title?: string;
  description?: string;
  data: {
    material: {
      name: string;
      quantityUnit: string;
    };
    quantity: number;
  }[];
}> = ({ data, title, description }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
     {title && <span className="block text-sm font-light text-gray-700">{title}</span>}
      {description && <span className="text-xs font-light">{description}</span>}
      <div className="mt-1 w-full overflow-hidden">
        {data?.length ? (
          <table className="min-w-full table-fixed divide-y divide-gray-300 border border-gray-300 rounded-md">
            <thead className="bg-gray-50">
              <tr className="divide-x divide-gray-200">
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[80%]"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 text-right"
                >
                  Quantity
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {data?.map((material, idx) => (
                <tr key={idx} className="divide-x divide-gray-200">
                  <td className="whitespace- px-2 py-2 text-sm text-gray-700 text-ellipsis overflow-hidden ">
                    {material.material?.name || "N/A"}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">
                    {numeral(material?.quantity || 0).format("0,0")}{" "}
                    {material.material.quantityUnit}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="rounded-md bg-amber-50 border border-amber-100 p-6 text-center mt-1 text-sm">
            No Materials Were Recorded
          </div>
        )}
      </div>
    </div>
  </div>
);

export default MaterialsInfoCard