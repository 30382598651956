import { gql, useQuery } from "@apollo/client";
import { SearchSelectInput } from "components/core";
import { FC } from "react";
import { displayUserName } from "utils";

interface EstimatorPickerProps {
  filter?: {
    region?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

const GET_USERS = gql`
  query GetAssignees(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
  ) {
    getUsers(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      roleName: "EST"
    ) {
      _id
      code
      lastName
      firstName
      ghanaCardNumber
      phoneNumber
      emailAddress
      gender
      profileImageUrl
      createdAt
      updatedAt
    }
  }
`;

export const EstimatorPicker: FC<EstimatorPickerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_USERS, {
    variables: {
      page: 1,
      pageSize: 1000,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "estimator"}
      label={label ?? "Estimator"}
      placeholder="Select Estimator"
      optionsLoading={loading}
      options={(data?.getUsers ?? [])?.map((user: any) => ({
        label: {
          title: displayUserName(user),
        },
        value: rawId ? user._id : user,
      }))}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default EstimatorPicker;
