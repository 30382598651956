import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { SelectInput, TextInput } from "../..";
import { FormikProps, useFormik } from "formik";
import { FC, useEffect } from "react";
import { wrapClick, classNames } from "utils";
import { ServiceRequestCategories, ServiceRequestTypes } from "apollo/data";
import { Switch } from "@headlessui/react";
import { RequestInfoSchema, ICreateServiceRequestFormSchema } from "./schema";
import moment from "moment";
import { RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { currentConfigVar } from "apollo/cache/config";
import lodash from "lodash";

const SEARCH_SERVICE_POINT = gql`
  query SearchServicePoints(
    $search: String!
    $searchField: String!
    $limit: NonNegativeInt
  ) {
    servicePoints: searchServicePoints(
      search: $search
      searchField: $searchField
      limit: $limit
    ) {
      _id
      code
      qrCode
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      block {
        _id
        code
        name
      }
      round {
        _id
        code
        # name
      }
      plot {
        _id
        code
        # name
      }
      property {
        _id
        code
        qrCode
        address
        ghanaPostAddress
        streetName
        houseNumber
        houseNumberSlateUrl
        community
        owner {
          fullName
          phoneNumber
        }
        region {
          _id
          code
          name
        }
        district {
          _id
          code
          name
        }
      }
      propertyCode
      customer {
        _id
        code
        customerType

        organization {
          name
          taxIdentificationNumber
          organizationRegistrationNumber
          organizationRegistrationDate
          organizationRegistrationDocumentUrl
          certificateOfIncorporationDocumentUrl
        }
        representative {
          title
          fullName
          nationality
          dateOfBirth
          gender
          phoneNumber
          emailAddress
          profileImageUrl
          hasGhanaCard
          ghanaCardNumber
          ghanaCardIssueDate
          ghanaCardExpiryDate
          ghanaCardFrontImageUrl
          ghanaCardBackImageUrl
          identityCardType
          identityCardNumber
          identityCardIssueDate
          identityCardExpiryDate
          identityCardFrontImageUrl
          identityCardBackImageUrl
        }
      }
      customerCode
      account {
        _id
        code
        status
      }
      accountCode
      meter {
        _id
        code
        model {
          _id
          code
          name
          brand {
            code
            name
          }
          type
          phase
          digits
        }
        status
      }
      meterCode
      serviceType
      serviceClass
      tariffClass {
        _id
        code
        name
        description
        serviceClass
      }
      geoCode
      transformerPhase
      meterLocation
      meterHeight
      transformerNumber
      currentTransformerRatio
      deliveryPointNumber
      contractedDemand
      poleNumber
      energyCertificateNumber
      energyCertificateDocumentUrl
      status
      createdAt
      updatedAt
    }
  }
`;

export interface RequestInfoFormProps {
  handleNext: (values: ICreateServiceRequestFormSchema["requestInfo"]) => void;
  handlePro: (
    step: number,
    lastStep: number,
    values: ICreateServiceRequestFormSchema["requestInfo"],
  ) => void;
  handlePrevious: () => void;
  initialValues: ICreateServiceRequestFormSchema["requestInfo"];
  values: ICreateServiceRequestFormSchema;
  handleCancel: () => void;
  parentForm: FormikProps<ICreateServiceRequestFormSchema>;
  step: number;
  lastStep: number;
}

function Owner({
  owner,
  onClick,
  isActive,
}: {
  owner: any;
  onClick: any;
  isActive: boolean;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        isActive
          ? "border-primary-500 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-primary-500"
          : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500",
      )}
    >
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {owner?.customer?.representative?.fullName ||
            owner?.customer?.orgarnization?.name ||
            "N A"}
        </p>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Account Number</span>
          <span>{owner?.accountCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Geo Code</span>
          <span>{owner?.geoCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Meter Number</span>
          <span>{owner?.meterCode || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Service Point</span>
          <span>{owner?.code || "N/A"}</span>
        </div>
        <div className="flex justify-between text-sm text-gray-700">
          <span className="text-gray-500">Ghana Post Address</span>
          <span>{owner?.property?.ghanaPostAddress || "N/A"}</span>
        </div>
      </div>
    </button>
  );
}

export const RequestInfoForm: FC<RequestInfoFormProps> = ({
  initialValues,
  handlePro,
  handleCancel,
  step,
  lastStep,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const form = useFormik<ICreateServiceRequestFormSchema["requestInfo"]>({
    initialValues,
    validationSchema: RequestInfoSchema,
    onSubmit: (values) => {
      handlePro(step, lastStep, values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });
  const [searchServicePoints, { loading, data }] =
    useLazyQuery(SEARCH_SERVICE_POINT);

  const servicePointSerachForm = useFormik({
    initialValues: {
      customerIdentifier: "customerCode",
      customerCode: "",
      accountCode: "",
      geoCode: "",
      meterCode: "",
      code: "",
    },
    onSubmit: (values) => {
      searchServicePoints({
        variables: {
          search: (values as any)[values.customerIdentifier],
          searchField: values.customerIdentifier,
          limit: 3,
        },
      });
    },
  });

  const selectServicePoint = (servicePoint: any) => async () => {
    if (servicePoint) {
      const { customer: existingCustomer, property: existingProperty } =
        servicePoint;
      if (existingCustomer) {
        form.setFieldValue("isExistingCustomer", true);
        form.setFieldValue("existingCustomer", existingCustomer || {});
      }
      if (existingProperty && form.values.type !== "NewServiceRequest") {
        form.setFieldValue("isExistingProperty", true);
        form.setFieldValue("existingProperty", existingProperty);
      }
      form.setFieldValue("existingServicePoint", servicePoint);
    }
  };

  function quantityText(quantity: number) {
    switch (quantity) {
      case 0: {
        return "no additional meter";
      }
      case 1: {
        return "1 additional meter";
      }
      default: {
        return `${quantity} additional meters`;
      }
    }
  }

  useEffect(() => {
    if (form.values.type) {
      form.setFieldValue("quantity", 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.type]);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Request Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <RadioGroup
                value={form.values.category}
                onChange={form.handleChange("category")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Request Category
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {lodash
                    .reject(ServiceRequestCategories, ["value", "Standard"])
                    .map((serviceRequestCategory) => (
                      <RadioGroup.Option
                        key={serviceRequestCategory.value}
                        value={serviceRequestCategory.value}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active
                              ? "border-primary-500 ring-2 ring-primary-500"
                              : "",
                            "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                          )
                        }
                      >
                        {({ checked, active }) => (
                          <>
                            <span className="flex flex-1">
                              <span className="flex flex-col">
                                <RadioGroup.Label
                                  as="span"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {serviceRequestCategory.title}
                                </RadioGroup.Label>
                                <RadioGroup.Description
                                  as="span"
                                  className="mt-1 flex items-center text-sm text-gray-500"
                                >
                                  {serviceRequestCategory.description}
                                </RadioGroup.Description>
                              </span>
                            </span>
                            <CheckCircleIcon
                              className={classNames(
                                !checked ? "invisible" : "",
                                "h-5 w-5 text-primary-600",
                              )}
                              aria-hidden="true"
                            />
                            <span
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-primary-500"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-lg",
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                </div>
              </RadioGroup>
            </div>
            <div className="col-span-3">
              <RadioGroup
                value={form.values.type}
                onChange={form.handleChange("type")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Request Type
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceRequestTypes.map((serviceRequestType) => (
                    <RadioGroup.Option
                      key={serviceRequestType.value}
                      value={serviceRequestType.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent" : "border-gray-300",
                          active
                            ? "border-primary-500 ring-2 ring-primary-500"
                            : "",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {serviceRequestType.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {serviceRequestType.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600",
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-primary-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg",
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            {["NewServiceRequest"].includes(form.values.type) && (
              <>
                <div className="col-span-1">
                  <TextInput
                    id="quantity"
                    label="Additional Meters"
                    type="number"
                    required={true}
                    min={0}
                    max={3}
                    step={1}
                    placeholder="e.g. 23"
                    {...form}
                    postText={form.values.quantity > 1 ? "meters" : "meter"}
                  />
                </div>

                {!form.errors.quantity && (
                  <div className="col-span-2 flex items-end">
                    <div className="rounded-md bg-blue-50 px-2.5 flex items-center flex-1 h-[38px]">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <InformationCircleIcon
                            className="h-5 w-5 text-blue-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                          <p className="text-sm text-blue-700">
                            1 mother meter with{" "}
                            {quantityText(form.values.quantity)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="pt-6">
          <span className="text-xs font-light">
            Existing Customer Information
          </span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              {["AdditionalLoadRequest", "SeparateMeterRequest"].includes(
                form.values.type,
              ) ? (
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">
                    Supply your existing Account Number, Customer Number,
                    GeoCode, Service Point Number or Meter Number.
                  </span>
                </div>
              ) : (
                <Switch.Group as="div">
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Existing Customer
                    </Switch.Label>
                  </span>
                  <div className="flex items-center justify-between mt-1  h-[38px]">
                    <Switch.Description
                      as="span"
                      className="text-sm text-gray-500"
                    >
                      Do you already have an account with ECG?
                      <br />
                      Supply your Account Number, Customer Number, GeoCode,
                      Service Point Number or Meter Number.
                    </Switch.Description>
                    <Switch
                      checked={form.values.isExistingCustomer}
                      onChange={(val: boolean) =>
                        form.setFieldValue("isExistingCustomer", val)
                      }
                      className={classNames(
                        form.values.isExistingCustomer
                          ? "bg-primary-600"
                          : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          form.values.isExistingCustomer
                            ? "translate-x-5"
                            : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                        )}
                      />
                    </Switch>
                  </div>
                </Switch.Group>
              )}
            </div>
          </div>
          {(form.values.isExistingCustomer ||
            form.values.type !== "NewServiceRequest") && (
            <div className="mt-6">
              <span className="text-xs font-light">Search for Account</span>
              <form
                onSubmit={servicePointSerachForm.handleSubmit}
                className="grid grid-cols-7 gap-6 mt-2"
              >
                <div className="col-span-3 sm:col-span-2">
                  <SelectInput
                    id="customerIdentifier"
                    label="Find Customer By"
                    options={[
                      { label: "Account Number", value: "accountCode" },
                      { label: "Customer Number", value: "customerCode" },
                      { label: "Geo Code", value: "geoCode" },
                      { label: "Service Point Number", value: "code" },
                      { label: "Meter Number", value: "meterCode" },
                    ]}
                    {...servicePointSerachForm}
                  />
                </div>

                {servicePointSerachForm.values?.customerIdentifier ===
                  "accountCode" && (
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      id="accountCode"
                      label="Account Number"
                      type="text"
                      placeholder="e.g. GD34934839893"
                      {...servicePointSerachForm}
                    />
                  </div>
                )}

                {servicePointSerachForm.values?.customerIdentifier ===
                  "customerCode" && (
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      id="customerCode"
                      label="Customer Code"
                      type="text"
                      placeholder="e.g. GD34934839893"
                      {...servicePointSerachForm}
                    />
                  </div>
                )}

                {servicePointSerachForm.values?.customerIdentifier ===
                  "geoCode" && (
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      id="geoCode"
                      label="Geo Code"
                      type="text"
                      placeholder="e.g. 0200000000"
                      {...servicePointSerachForm}
                    />
                  </div>
                )}

                {servicePointSerachForm.values?.customerIdentifier ===
                  "code" && (
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      id="code"
                      label="Service Point Number"
                      type="text"
                      placeholder="e.g. GHA-725667795-8"
                      {...servicePointSerachForm}
                    />
                  </div>
                )}

                {servicePointSerachForm.values?.customerIdentifier ===
                  "meterCode" && (
                  <div className="col-span-6 sm:col-span-3">
                    <TextInput
                      id="meterCode"
                      label="Meter Number"
                      type="text"
                      placeholder="e.g. MR12345678"
                      {...servicePointSerachForm}
                    />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-2 items-end justify-end flex">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  >
                    {loading ? "Searching Customer..." : "Search Customer"}
                  </button>
                </div>
              </form>
              <div className="grid grid-cols-3 gap-6 mt-6 pt-6 border-t border-gray-200">
                {data?.servicePoints?.map?.((servicePoint: any) => (
                  <Owner
                    key={servicePoint._id}
                    isActive={
                      servicePoint._id ===
                      form.values?.existingServicePoint?._id
                    }
                    onClick={selectServicePoint(servicePoint)}
                    owner={servicePoint}
                  />
                ))}
              </div>
              {form?.values?.existingServicePoint && (
                <div className="mt-6 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                  <div className="grid grid-cols-4 gap-4 p-4">
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Type
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer
                          ?.customerType || "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Title
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer
                          ?.representative?.title ||
                          form?.values?.existingServicePoint?.customer
                            ?.representative?.title ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Full Name
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer
                          ?.representative?.fullName ||
                          form?.values?.existingServicePoint?.customer
                            ?.representative?.fullName ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Nationality
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer
                          ?.representative?.nationality ||
                          form?.values?.existingServicePoint?.customer
                            ?.representative?.nationality ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Date of Birth
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {moment(
                          form?.values?.existingServicePoint?.customer
                            ?.representative?.dateOfBirth ||
                            form?.values?.existingServicePoint?.customer
                              ?.representative?.dateOfBirth,
                        ).format(dateFormat)}
                      </div>
                    </div>
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Gender
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer
                          ?.representative?.gender ||
                          form?.values?.existingServicePoint?.customer
                            ?.representative?.gender ||
                          "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 p-4">
                    <div className="">
                      <span className="block text-sm font-light text-gray-700">
                        Account Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.account?.code ||
                          "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Customer Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.customer?.code ||
                          "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Property Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.property?.code ||
                          "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Service Point Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.code || "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Geographical Code
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.geoCode || "N/A"}
                      </div>
                    </div>
                    <div>
                      <span className="block text-sm font-light text-gray-700">
                        Meter Number
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.meter?.code ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <span className="block text-sm font-light text-gray-700">
                        Property Address
                      </span>
                      <div className="mt-1 block w-full sm:text-sm">
                        {form?.values?.existingServicePoint?.meter?.code ||
                          "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          disabled={!form.isValid}
          className={classNames(
            form.isValid ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm",
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RequestInfoForm;
