import lodash from "lodash";

export const displayUserName = (user?: { firstName: string; lastName: string; }, defaultValue = 'Unavailable'): string => {
  if(!user){
    return defaultValue
  }
  if(user?.firstName && user.firstName === user.lastName) {
    return user.firstName.replace(/\w+/g, lodash.capitalize)
  }
  return (`${user?.firstName  } ${  user?.lastName}`).replace(/\w+/g, lodash.capitalize)
}
