import { gql, useMutation, useQuery } from "@apollo/client";
import { ContractorUserView, Modal } from "components";
import { FC } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { withRoles, wrapClick } from "utils";
import { GET_CONTRACTOR_USER } from "./view";

const DISABLE_METER_CONTRACTOR_USER = gql`
  mutation DisablePartnerUser($id: ID!) {
    disablePartnerUser(id: $id) {
      _id
    }
  }
`;

interface DisablePartnerUserContainerProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}

const DisablePartnerUserContainer: FC<DisablePartnerUserContainerProps> = ({
  open,
  setOpen,
  refetch,
}) => {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CONTRACTOR_USER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  const [disablePartnerUser, { loading: loadingDelete }] = useMutation(
    DISABLE_METER_CONTRACTOR_USER,
  );

  const deleteItem = async () => {
    await disablePartnerUser({
      variables: {
        id: searchParams.id,
      },
    }).then(({ data }) => {
      if (data.disablePartnerUser._id) {
        toast(
          JSON.stringify({
            type: "success",
            title: "User Disabled Successfully",
          }),
        );
        refetch?.();
        setOpen(false);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not disable User" }),
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      title="Disable User"
      description="Are you sure you want to disable this user?  "
      renderActions={() => (
        <>
          {withRoles(["Super"])(
            <button
              type="button"
              disabled={loadingDelete}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={wrapClick(deleteItem)}
            >
              {loadingDelete ? "Disabling User..." : "Disable User"}
            </button>,
          )}
        </>
      )}
    >
      {data?.user?._id && <ContractorUserView user={data?.user} />}
    </Modal>
  );
};

export default DisablePartnerUserContainer;
