import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import lodash from "lodash";
import { FC, useMemo } from "react";
import { classNames } from "utils";

interface Option {
  label: {
    title: string;
    imageUrl?: string;
  };
  value: string | object;
}

interface AutocompleteInputProps {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  options: Option[];
  optionsLoading?: boolean;
  setFieldValue: any;
  query: string;
  setQuery: (val: string) => void;
}

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  id,
  options: __options,
  values,
  setFieldValue,
  placeholder,
  label,
  errors,
  touched,
  required,
  labelHidden,
  disabled,
  query,
  setQuery,
}) => {
  const options = useMemo(
    () =>
      __options.map(({ label, value }) => ({
        label,
        value: lodash.isObject(value)
          ? lodash.omit(value, "__typename")
          : value,
      })),
    [__options],
  );

  return (
    <Combobox
      as="div"
      disabled={disabled}
      value={lodash.get(values, id, null)}
      onChange={(val) => setFieldValue(id, val)}
    >
      {!labelHidden && (
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {label} {required ? <span className="text-red-500">*</span> : ""}
        </Combobox.Label>
      )}
      <div className={classNames(labelHidden ? "" : "mt-1", "relative")}>
        <Combobox.Input
          className={classNames(
            disabled ? "cursor-not-allowed bg-gray-100" : "bg-white",
            "w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm placeholder:font-light placeholder:text-xs",
          )}
          placeholder={placeholder}
          autoComplete="none"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={lodash.get(values, id)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {options.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Combobox.Option
                key={JSON.stringify(option.value)}
                value={option.value}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-primary-600 text-white" : "text-gray-900",
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {option.label.imageUrl && (
                        <img
                          src={option.label.imageUrl}
                          alt=""
                          className="h-6 w-6 flex-shrink-0 rounded-full object-cover"
                        />
                      )}
                      <span
                        className={classNames(
                          option.label.imageUrl ? "ml-3" : "",
                          "truncate",
                          selected ? "font-semibold" : "",
                        )}
                      >
                        {option.label.title}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary-600",
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
      {lodash.get(errors, id) && lodash.get(touched, id) ? (
        <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {lodash.get(errors, id)}
        </p>
      ) : null}
    </Combobox>
  );
};
