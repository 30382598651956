export const ServiceTypes = [
  {
    value: "Postpaid",
    title: "Postpaid",
    description: "Payments after consumption",
  },
  {
    value: "Prepaid",
    title: "Prepaid",
    description: "Payments made before consumption",
  },
] as const;
export type ServiceType = (typeof ServiceTypes)[number]["value"];
