import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetInstallationServiceOrders(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    rows: getInstallationServiceOrders(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      contractorAssigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      farmingOutContractor {
        _id
        code
        name
      }
      farmingOutContractorUser {
        _id
        code
        username
        lastName
        firstName
      }
      category
      status
      priority
      estimatedResolutionDate
      servicePointCode
      servicePoint {
        _id
        code
        geoCode
      }
      request {
        _id
        code
        type
        inspection {
          resolution {
            service {
              meterPhase
              tariffClass {
                _id
                code
                name
              }
            }
          }
        }
      }
      installationType {
        _id
        code
        name
      }
      installedMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeterSystemCode
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      assignedAt
      startedAt
      resolvedAt
      completedAt
      reverseSyncedAt
      rejectedAt
      createdAt
      updatedAt
    }
    count: getInstallationServiceOrdersCount(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetInstallationServiceOrderSummary(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    summary: getInstallationServiceOrderSummary(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      category: $category
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      Pending
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Disapproved
      Completed
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $category: InstallationServiceOrderCategory
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
    $description: String
  ) {
    url: getInstallationServiceOrderExportUrl(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      category: $category
      description: $description
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_ORDER = gql`
  fragment ServiceOrderPrepaidSync on PrepaidSync {
    _id
    status
    stage
    completedAt
    data {
      servicePointCode
      regionCode
      customer {
        surname
        otherNames
        idNo
        idType
        taxRefNo
        address1
        address2
        address3
        telephone1
        telephone2
        telephone3
        fax
        email
      }
      servicePoint {
        address1
        address2
        address3
        geoCode
        digitalAddress
      }
      tariffClass {
        tariffCode
        tariffGroup
      }
      meter {
        meterSerialNo
        batchNo
        make
        model
        phase
        systemDetails
      }
      debt {
        debtType
        debtAmount
        debtRef
        instalmentDueDate
        debtStatus
      }
    }
    history {
      action
      data
      message
      timestamp
      status
    }
  }

  fragment MeterDetail on Meter {
    _id
    code
    modelMeta {
      brandCode
      brandName
      systemCode
      systemName
      modelCode
      modelName
      phase
    }
    syncMethod
  }

  query GetInstallationServiceOrder($id: ID!) {
    serviceOrder: getInstallationServiceOrder(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      request {
        _id
        code
        type
        response {
          servicePoint {
            _id
            code
          }
        }
        inspection {
          resolution {
            property {
              propertyImageUrls
            }
            service {
              meterPhase
              tariffClass {
                _id
                code
                name
              }
            }
            materials {
              material {
                _id
                code
                name
                quantityUnit
              }
              quantity
            }
          }
        }
      }
      installationType {
        _id
        code
        name
      }
      meterContractor {
        _id
        code
        name
      }
      farmingOutContractorUser {
        _id
        code
        username
        firstName
        lastName
        phoneNumber
        emailAddress
      }
      farmingOutContractor {
        _id
        code
        name
        ghanaPostAddress
        contactPerson {
          emailAddress
          fullName
          phoneNumber
        }
      }
      assigneeType
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      contractorAssigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      resolution {
        property {
          poleNumber
          propertyImageUrls
        }
        service {
          qrCode
          meter {
            ...MeterDetail
          }
        }
        reverseSyncMeter {
          ...MeterDetail
        }
        reverseSyncReading {
          readingDate
          readingValue
        }
        reading {
          readingDate
          readingValue
          readingImageUrl
        }
        notes
        materials {
          quantity
          material {
            _id
            code
            name
            quantityUnit
            quantity
            updatedAt
            category
          }
        }
      }
      category
      status
      result
      priority
      history {
        actor {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      notes
      estimatedResolutionDate
      installationMeter {
        ...MeterDetail
      }
      installedMeter {
        ...MeterDetail
      }
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      installationSync {
        ...ServiceOrderPrepaidSync
      }
      presetAmountSync {
        ...ServiceOrderPrepaidSync
      }
      assignedAt
      contractorAssignedAt
      startedAt
      resolvedAt
      contractorResolvedAt
      completedAt
      rejectedAt
      reverseSyncedAt
      createdAt
      updatedAt
    }
  }
`;
