import { FC } from "react";
import { ICurrentConfig } from "apollo/cache/config";
import moment from "moment";
import lodash from "lodash";
import { wrapImage } from "utils";
import { IIdentityInfoFormSchema } from "./schema";
import { CustomerType } from "apollo/data";

export const IdentityInfoFormSummary: FC<{
  data: IIdentityInfoFormSchema;
  values: {
    customerInfo: {
      customerType: CustomerType;
    };
  };
  config: ICurrentConfig;
}> = ({ data, values, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    {values?.customerInfo?.customerType === "Individual" && (
      <>
        <div className="">
          <span className="text-xs font-light">
            Customer Identity Information
          </span>
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Has Ghana Card
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.representative?.hasGhanaCard ? "Yes" : "No"}
              </div>
            </div>
            {!data?.representative?.hasGhanaCard && (
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {lodash.startCase(data?.representative?.identityCardType) ||
                    "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.representative?.hasGhanaCard ? (
          <div className="pt-6">
            <span className="text-xs font-light">
              Customer Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.representative?.ghanaCardNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Issue Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.ghanaCardIssueDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Expiry Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.ghanaCardExpiryDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Front Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardFrontImageUrl}
                      alt={"front"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Back Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardBackImageUrl}
                      alt={"back"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-6">
            <span className="text-xs font-light">
              Customer Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.representative?.identityCardNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Issue Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.identityCardIssueDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    ID Expiry Date
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {moment(
                      data?.representative?.identityCardExpiryDate,
                    ).format(dateFormat)}
                  </div>
                </div>
              )}
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  ID Front Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.identityCardFrontImageUrl}
                      alt={"front"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType === "DriversLicense" && (
                <div className="col-span-3">
                  <span className="block text-sm font-light text-gray-700">
                    ID Back Image
                  </span>
                  <div className="mt-2">
                    {wrapImage(
                      <img
                        src={data?.representative?.identityCardBackImageUrl}
                        alt={"back"}
                        className="w-full h-64 text-xs"
                      />,
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )}
    {values?.customerInfo?.customerType === "Organization" && (
      <div className="">
        <span className="text-xs font-light">
          Organization Identity Information
        </span>
        <div className="grid grid-cols-6 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Tax Identification Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.organization?.taxIdentificationNumber || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Organization Registration Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.organization?.organizationRegistrationNumber || "N/A"}
            </div>
          </div>
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Organization Registration Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {moment(data?.organization?.organizationRegistrationDate).format(
                dateFormat,
              )}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Certificate of Incorporation
            </span>
            <div className="mt-2">
              {wrapImage(
                <img
                  src={
                    data?.organization?.certificateOfIncorporationDocumentUrl
                  }
                  alt={"Certificate of Incorporation"}
                  className="w-full h-64 text-xs"
                />,
              )}
            </div>
          </div>
          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Business Regsitration Document
            </span>
            <div className="mt-2">
              {wrapImage(
                <img
                  src={data?.organization?.organizationRegistrationDocumentUrl}
                  alt={"Business Regsitration Document"}
                  className="w-full h-64 text-xs"
                />,
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    {values?.customerInfo?.customerType === "Organization" && (
      <>
        <div className="pt-6">
          <span className="text-xs font-light">
            Representative Identity Information
          </span>
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Has Ghana Card
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.representative?.hasGhanaCard ? "Yes" : "No"}
              </div>
            </div>
            {!data?.representative?.hasGhanaCard && (
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Type
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {lodash.startCase(data?.representative?.identityCardType) ||
                    "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.representative?.hasGhanaCard ? (
          <div className="pt-6">
            <span className="text-xs font-light">
              Representative Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.representative?.ghanaCardNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Issue Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.ghanaCardIssueDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Expiry Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.ghanaCardExpiryDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Front Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardFrontImageUrl}
                      alt={"front"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  Ghana Card Back Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.ghanaCardBackImageUrl}
                      alt={"back"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-6">
            <span className="text-xs font-light">
              Representative Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Number
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {data?.representative?.identityCardNumber || "N/A"}
                </div>
              </div>
              <div className="col-span-2">
                <span className="block text-sm font-light text-gray-700">
                  ID Issue Date
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {moment(data?.representative?.identityCardIssueDate).format(
                    dateFormat,
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    ID Expiry Date
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {moment(
                      data?.representative?.identityCardExpiryDate,
                    ).format(dateFormat)}
                  </div>
                </div>
              )}
              <div className="col-span-3">
                <span className="block text-sm font-light text-gray-700">
                  ID Front Image
                </span>
                <div className="mt-2">
                  {wrapImage(
                    <img
                      src={data?.representative?.identityCardFrontImageUrl}
                      alt={"front"}
                      className="w-full h-64 text-xs"
                    />,
                  )}
                </div>
              </div>
              {data?.representative?.identityCardType === "DriversLicense" && (
                <div className="col-span-3">
                  <span className="block text-sm font-light text-gray-700">
                    ID Back Image
                  </span>
                  <div className="mt-2">
                    {wrapImage(
                      <img
                        src={data?.representative?.identityCardBackImageUrl}
                        alt={"back"}
                        className="w-full h-64 text-xs"
                      />,
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    )}
  </div>
);
