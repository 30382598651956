export const ServiceRequestCategories = [
  {
    value: "Standard",
    title: "Standard",
    description: "14-day service delivery with GHS100.00 processing fee",
  },
  {
    value: "Express",
    title: "Express",
    description: "5-day service delivery with GHS150.00 processing fee",
  },
  {
    value: "Premium",
    title: "Premium",
    description: "1-Day service delivery with GHS200.00 processing fee",
  },
] as const;
export type ServiceRequestCategory =
  (typeof ServiceRequestCategories)[number]["value"];
