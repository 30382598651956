import { useQuery, useReactiveVar } from "@apollo/client";
import {
  CalendarHeader,
  HeaderTabs,
  Shimmers,
  TableComponent,
} from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, useTableData } from "utils";
import config from "config";
import ViewInstallationServiceOrderContainer from "./view";
import { Avatar, SearchSelectInput, StatusBadge } from "components/core";
import { currentConfigVar } from "apollo/cache/config";
import ActionButton, { Action } from "components/buttons/action-button";
import lodash from "lodash";
import { currentDistrictVar } from "apollo/cache/auth";
import { GET_SERVICE_ORDERS, GET_SERVICE_ORDER_SUMMARY } from "./apollo";
import { ContractorPicker, ContractorUserPicker } from "containers";
import ExportDataContainer from "./export";

const mainTabs = [
  { name: "Pending", href: "Pending", sort: "-createdAt" },
  { name: "Ass. To Supp.", href: "AssignedToSupplier", sort: "-assignedAt" },
  {
    name: "Ass. To Inst.",
    href: "AssignedToInstaller",
    sort: "-assignedAt",
  },
  { name: "In Progress", href: "InProgress", sort: "-startedAt" },
  { name: "Resolved", href: "Resolved", sort: "-resolvedAt" },
  { name: "Disapproved", href: "Disapproved", sort: "-disapprovedAt" },
  { name: "Completed", href: "Completed", sort: "-completedAt" },
];

const InstallationServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [category, setCategory] = useUrlState("category");
  const [contractor, setContractor] = useUrlState("contractor");
  const [contractorUser, setContractorUser] = useUrlState("contractorUser");
  const [prepaidSyncStatus, setPrepaidSyncStatus] =
    useUrlState("prepaidSyncStatus");
  const [orderStatus] = useUrlState("orderStatus");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
        "accountCode",
        "servicePointCode",
        "serviceRequestCode",
      ],
      serviceClasses: ["Residential", "NonResidential"],
      ...(currentDistrict ? { district: currentDistrict } : {}),
      status: searchParams.orderStatus || undefined,
      installationMeterSystemSyncStatus:
        searchParams.prepaidSyncStatus || undefined,
      sort: mainTabs?.find((item) => item.href === searchParams.orderStatus)
        ?.sort,
      category: searchParams.category || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      farmingOutContractor: searchParams.contractor || undefined,
      farmingOutContractorUser: searchParams.contractorUser || undefined,
      dateField: "assignedAt",
    }),
    [searchParams, currentDistrict],
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    GET_SERVICE_ORDERS,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        refetchSummary?.();
      },
    },
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_SERVICE_ORDER_SUMMARY,
    {
      variables: filter,
      notifyOnNetworkStatusChange: false,
    },
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.orderStatus ? { orderStatus: "Pending" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  useEffect(() => {
    setContractorUser(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <CalendarHeader />
      <HeaderTabs
        mainTabs={mainTabs}
        tabKey={"orderStatus"}
        summary={dataSummary?.summary}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"service orders"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderExport={({ exportOpen, setExportOpen }) => (
              <ExportDataContainer
                entityType={""}
                open={exportOpen}
                setOpen={setExportOpen}
                filter={filter}
              />
            )}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                {orderStatus !== "Pending" &&
                  orderStatus !== "AssignedToSupplier" && (
                    <>
                      <ContractorPicker
                        id="contractor"
                        labelHidden={true}
                        label={""}
                        placeholder="Select Contractor"
                        setFieldValue={(_: any, value: string) =>
                          setContractor(value)
                        }
                        filter={{ district: currentDistrict || undefined }}
                        values={{ contractor }}
                        rawId={true}
                      />
                      {contractor && (
                        <ContractorUserPicker
                          id="contractorUser"
                          labelHidden={true}
                          label={""}
                          placeholder="Select Agent"
                          setFieldValue={(_: any, value: string) =>
                            setContractorUser(value)
                          }
                          filter={{ contractor }}
                          values={{ contractorUser }}
                          rawId={true}
                        />
                      )}
                    </>
                  )}
                <SearchSelectInput
                  id="category"
                  labelHidden={true}
                  options={[
                    { label: { title: "Express" }, value: "Express" },
                    { label: { title: "Premium" }, value: "Premium" },
                    { label: { title: "Standard" }, value: "Standard" },
                  ]}
                  label={""}
                  placeholder="Select Category"
                  setFieldValue={(_: any, value: string) => setCategory(value)}
                  values={{ category }}
                />
                {(["Completed"].includes(searchParams?.orderStatus as string) ||
                  !searchParams?.orderStatus) && (
                  <SearchSelectInput
                    id="prepaidSyncStatus"
                    labelHidden={true}
                    options={[
                      { label: { title: "Pending" }, value: "Pending" },
                      { label: { title: "Attempted" }, value: "Attempted" },
                      {
                        label: { title: "Forward Synced" },
                        value: "ForwardSynced",
                      },
                      {
                        label: { title: "Reverse Synced" },
                        value: "ReverseSynced",
                      },
                      { label: { title: "Completed" }, value: "Completed" },
                    ]}
                    label={""}
                    placeholder="Select Sync Status"
                    setFieldValue={(_: any, value: string) =>
                      setPrepaidSyncStatus(value)
                    }
                    values={{ prepaidSyncStatus }}
                  />
                )}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Code | Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Location
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Service Point
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Tariff Class
                </th>
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Assigned Meter
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Installed Meter
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Category
                </th>
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Prepayment Sync Status
                  </th>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Assigned | ETA
                  </th>
                )}
                {["Completed"].includes(orderStatus) && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Resolution Period
                  </th>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Assigned By
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Farming Out Contractor | Agent
                    </th>
                  </>
                )}
                {!orderStatus && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Status
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                ></th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.SingleShimmer />
                </td>
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                )}

                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.AvatarShimmer />
                    </td>
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.AvatarShimmer />
                    </td>
                  </>
                )}
                {!orderStatus && (
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200">
                  <Shimmers.ActionsShimmer actionsCount={1} />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                  <div className="font-medium text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="font-medium text-gray-500 dark:text-gray-400">
                    {moment(item?.assignedAt).format(dateFormat)}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.contactPerson?.phoneNumber || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.location?.address || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.location?.community || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {lodash.startCase(item?.request?.type) || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.installationType?.name || "N/A"}{" "}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.servicePoint?.geoCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.request?.inspection?.resolution?.service?.tariffClass
                      ?.code || "N/A"}{" "}
                    (
                    {item?.request?.inspection?.resolution?.service?.tariffClass
                      ?.name || "N/A"}
                    )
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.request?.inspection?.resolution?.service
                      ?.meterPhase || "N/A"}{" "}
                    Phase
                  </div>
                </td>
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.installationMeter?.code || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.installationMeter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.installationMeter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.installedMeter?.code || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.installedMeter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.installedMeter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                  <StatusBadge
                    status={item?.category}
                    options={{
                      Premium: "bg-green-100 text-green-800",
                      Standard: "bg-primary-100 text-primary-800",
                      Express: "bg-orange-100 text-orange-800",
                    }}
                  />
                </td>
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <StatusBadge
                      status={item?.installationMeterSystemSyncStatus}
                      options={{
                        ForwardSynced: "bg-blue-100 text-blue-800",
                        ReverseSynced: "bg-orange-100 text-orange-800",
                        Completed: "bg-green-100 text-green-800",
                        Pending: "bg-yellow-100 text-yellow-800",
                        Attempted: "bg-red-100 text-red-800",
                      }}
                    />
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                ].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      {item?.estimatedResolutionDate
                        ? moment(item?.estimatedResolutionDate).format(
                            dateFormat,
                          )
                        : "N/A"}
                    </div>
                  </td>
                )}
                {["Completed"].includes(orderStatus) && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="text-gray-900 dark:text-gray-100">
                      <span className="text-xs mr-2 font-light">from</span>
                      {item?.assignedAt
                        ? moment(item?.assignedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-400">
                      <span className="text-xs mr-2 font-light">to</span>
                      {item?.completedAt
                        ? moment(item?.completedAt).format(dateFormat)
                        : "N/A"}
                    </div>
                  </td>
                )}
                {[
                  "AssignedToSupplier",
                  "AssignedToInstaller",
                  "InProgress",
                  "Resolved",
                  "Disapproved",
                  "Completed",
                ].includes(orderStatus) && (
                  <>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            alt={
                              [
                                (
                                  item?.contractorAssigner?.lastName || ""
                                )?.trim(),
                                (
                                  item?.contractorAssigner?.firstName || ""
                                )?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"
                            }
                            src={
                              item?.contractorAssigner?.profileImageUrl || ""
                            }
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-900 dark:text-gray-100">
                            {[
                              (
                                item?.contractorAssigner?.lastName || ""
                              )?.trim(),
                              (
                                item?.contractorAssigner?.firstName || ""
                              )?.trim(),
                            ]
                              .join(" ")
                              .trim() || "N A"}
                          </div>
                          <div className="text-gray-500 dark:text-gray-400">
                            {item?.contractorAssigner?.phoneNumber || "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          <Avatar
                            disabled={true}
                            alt={item?.farmingOutContractor?.name || "N A"}
                          />
                        </div>
                        <div className="ml-4">
                          <div className="text-gray-900 dark:text-gray-100">
                            {item?.farmingOutContractor?.name || "N/A"}
                          </div>
                          <div className=" text-gray-500 dark:text-gray-400">
                            {item?.farmingOutContractorUser?.username ||
                              [
                                item?.farmingOutContractorUser?.firstName?.trim(),
                                item?.farmingOutContractorUser?.lastName?.trim(),
                              ]
                                .join(" ")
                                ?.trim() ||
                              "N/A"}
                          </div>
                        </div>
                      </div>
                    </td>
                  </>
                )}
                {!orderStatus && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <StatusBadge
                      status={item?.status}
                      options={{
                        Pending: "bg-green-100 text-green-800",
                        Cancelled: "bg-red-100 text-red-800",
                      }}
                    />
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                  <div className="space-x-1">
                    <ActionButton
                      action="view"
                      onClick={dispatchAction(item?._id, "view")}
                    />
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <ViewInstallationServiceOrderContainer
              open={modal === "view"}
              setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            />
          )}
        </>
      )}
    </main>
  );
};

export default InstallationServiceOrdersPage;
