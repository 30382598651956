export const ServiceRequestTypes = [
  {
    value: "NewServiceRequest",
    title: "New Service",
    description:
      "Facilities that have never had electricity, that is no service point",
  },
  {
    value: "SeparateMeterRequest",
    title: "Separate Meter",
    description:
      "Existing service points with monthly consumption greater than 300KWh",
  },
  {
    value: "AdditionalLoadRequest",
    title: "Additional Load",
    description: "Increasing the load on existing service point to 400V",
  },
] as const;

export type ServiceRequestType = (typeof ServiceRequestTypes)[number]["value"];
