import { Outlet, Route } from "react-location";
import {
  DashboardPage,
  ContractorsPage,
  ForgotPasswordPage,
  SigninPage,
  SettingsPage,
  ContractorUsersPage,
  InstallationServiceOrdersPage,
  UsersPage,
  PrepayemtErrorsPage,
  ExportsPage,
  InspectionServiceOrdersPage,
  ServiceRequestsPage,
  ServiceRequestPage,
  CreateServiceRequestPage,
} from "pages";
import { UserRole } from "apollo/cache/auth";
import {
  ArrowDownTrayIcon,
  BriefcaseIcon,
  ChartBarSquareIcon,
  Cog8ToothIcon,
  CogIcon,
  SquaresPlusIcon,
  UserPlusIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export type RouteProps = Omit<Route, "children"> & {
  withRoles?: UserRole[];
  navigation?: boolean;
  navbar?: string;
  sidebar?: { label: string; icon: any };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: ChartBarSquareIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "General",
    },
  },
  {
    path: "data-exports",
    element: <ExportsPage />,
    navbar: "Data Exports",
    sidebar: {
      label: "Data Exports",
      icon: ArrowDownTrayIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Data Exports",
      section: "General",
    },
  },
  {
    path: "service-requests",
    element: <Outlet />,
    sidebar: {
      label: "Service Requests",
      icon: UserPlusIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Service Requests",
      section: "General",
    },
    children: [
      {
        element: <ServiceRequestsPage />,
        meta: {
          layout: "App",
          section: "General",
        },
      },
      {
        path: "new",
        element: <CreateServiceRequestPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Create Service Request",
          section: "General",
        },
      },
      {
        path: ":serviceRequest",
        element: <ServiceRequestPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Service Request",
          section: "General",
        },
      },
    ],
  },
  {
    path: "inspections",
    element: <InspectionServiceOrdersPage />,
    sidebar: {
      label: "Inspections Orders",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Inspection Service Orders",
      section: "Orders",
    },
  },
  {
    path: "installations",
    element: <InstallationServiceOrdersPage />,
    navbar: "Installation Orders",
    sidebar: {
      label: "Installation Orders",
      icon: SquaresPlusIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Installation Service Orders",
      section: "Orders",
    },
  },
  {
    path: "prepayment-errors",
    element: <PrepayemtErrorsPage />,
    sidebar: {
      label: "Prepayment Errors",
      icon: CogIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Prepayment Errors",
      section: "Configurations",
    },
  },
  {
    path: "users",
    element: <UsersPage />,
    navbar: "Users",
    sidebar: {
      label: "Users",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Users",
      section: "Configurations",
    },
    withRoles: ["Super"],
  },
  {
    path: "contractors",
    element: <Outlet />,
    sidebar: {
      label: "Farming Out Contractors",
      icon: BriefcaseIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Farming Out Contractors",
      section: "Configurations",
    },
    withRoles: ["Super", "Admin"],
    children: [
      {
        path: "/",
        element: <ContractorsPage />,
        meta: {
          layout: "App",
          // breadcrumb: () => "Contractors",
          section: "Configurations",
        },
        withRoles: ["Super", "Admin"],
      },
      {
        path: ":contractor",
        element: <ContractorUsersPage />,
        meta: {
          layout: "App",
          breadcrumb: () => "Farming Out Contractors Agents",
          section: "Configurations",
        },
        withRoles: ["Super", "Admin"],
      },
    ],
  },
  {
    path: "settings",
    element: <SettingsPage />,
    sidebar: {
      label: "Settings",
      icon: Cog8ToothIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Settings",
      section: "Configurations",
    },
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
];

export default routes;
