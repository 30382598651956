import { FC } from "react";
import { SearchSelectInput } from "components";
import { Configs } from "apollo/data";

interface PremiseTypePickerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

export const PremiseTypePicker: FC<PremiseTypePickerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  return (
    <SearchSelectInput
      id={id ?? "premiseType"}
      label={label ?? "Premise Type"}
      placeholder="Select Premise Type"
      options={Configs?.filter((el) => el?.type === "PremiseType")?.map(
        (premiseType: any) => ({
          label: {
            title: premiseType.name as string,
          },
          value: rawId ? premiseType?._id : premiseType,
        }),
      )}
      {...form}
      disabled={form.disabled}
    />
  );
};
