import { FC } from "react";
import { ICreateServiceRequestFormSchema } from "./schema";
import { wrapImage } from "utils";
import { MapProvider, Marker } from "react-map-gl";

import Map from "react-map-gl";
import config from "config";

const PropertyInfoSummary: FC<{
  data: ICreateServiceRequestFormSchema["propertyInfo"];
}> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
      <span className="text-xs font-light">Owner Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Full name
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.owner?.fullName || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Phone Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.owner?.phoneNumber || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Property Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">Region</span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.region?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            District
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.district?.name || "N/A"}
          </div>
        </div>

        <div>
          <span className="block text-sm font-light text-gray-700">
            Ghana Post Address
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.ghanaPostAddress || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Street Name
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.streetName || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            House Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.houseNumber || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Community
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.community || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Closest Landmark
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.landmark || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Premise Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Premise Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.premiseType?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Premise Category
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.premiseCategory?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Activity
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.activity?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Sub Activity
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.subActivity?.name || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6 grid grid-cols-2 gap-6">
      <div>
        <span className="text-xs font-light">Site Plan Information</span>
        {data?.sitePlanDocumentUrl ? (
          <div className="mt-2">
            {wrapImage(
              <img
                src={data?.sitePlanDocumentUrl}
                alt={data?.structureNumber}
                className="w-full h-64 text-xs"
              />,
            )}
          </div>
        ) : (
          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
            Site plan not provided
          </div>
        )}
      </div>
      <div>
        <span className="text-xs font-light">Map Information</span>
        <div className="mt-2 w-full h-64 rounded-md overflow-hidden">
          <MapProvider>
            <Map
              id="service-location"
              initialViewState={{
                longitude: data?.geoLocation?.longitude || -0.234361,
                latitude: data?.geoLocation?.latitude || 5.667032,
                zoom: 15,
              }}
              mapStyle="mapbox://styles/mapbox/streets-v12"
              mapboxAccessToken={config.mapbox.token}
            >
              <Marker
                longitude={data?.geoLocation?.longitude || -0.234361}
                latitude={data?.geoLocation?.latitude || 5.667032}
              >
                <img className="w-6 h-6" src={"/logo.png"} alt={"pin"} />
              </Marker>
            </Map>
          </MapProvider>
        </div>
      </div>
    </div>
  </div>
);

export default PropertyInfoSummary;
