export const ServiceClasses = [
  {
    value: "Residential",
    title: "Residential",
    description: "Service Supply for Residential Purposes",
  },
  {
    value: "NonResidential",
    title: "Non - Residential",
    description: "Service Supply for Commercial Purposes",
  },
] as const;
export type ServiceClass = (typeof ServiceClasses)[number]["value"];
