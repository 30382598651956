import { TextInput, UploadArea } from "../..";
import { useFormik } from "formik";
import { FC } from "react";
import { wrapClick, classNames } from "utils";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ICreateServiceRequestFormSchema, ServiceInfoSchema } from "./schema";
import { ServiceClasses, ServiceTypes } from "apollo/data";

export interface ServiceInfoFormProps {
  handleNext: (values: ICreateServiceRequestFormSchema["serviceInfo"]) => void;
  handlePrevious: () => void;
  initialValues: ICreateServiceRequestFormSchema["serviceInfo"];
  values: ICreateServiceRequestFormSchema;
  handleCancel: () => void;
}

export const ServiceInfoForm: FC<ServiceInfoFormProps> = ({
  initialValues,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<ICreateServiceRequestFormSchema["serviceInfo"]>({
    initialValues,
    validationSchema: ServiceInfoSchema,
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        <div>
          <span className="text-xs font-light">Service Information</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            <div className="col-span-3">
              <RadioGroup
                value={form.values.serviceType}
                onChange={form.handleChange("serviceType")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Service Type
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceTypes.map((serviceType) => (
                    <RadioGroup.Option
                      key={serviceType.value}
                      value={serviceType.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent" : "border-gray-300",
                          active
                            ? "border-primary-500 ring-2 ring-primary-500"
                            : "",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {serviceType.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {serviceType.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600",
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-primary-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg",
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className="col-span-3">
              <RadioGroup
                value={form.values.serviceClass}
                onChange={form.handleChange("serviceClass")}
              >
                <RadioGroup.Label className="text-sm font-medium text-gray-700">
                  Service Class
                </RadioGroup.Label>
                <div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
                  {ServiceClasses.map((serviceClass) => (
                    <RadioGroup.Option
                      key={serviceClass.value}
                      value={serviceClass.value}
                      className={({ checked, active }) =>
                        classNames(
                          checked ? "border-transparent" : "border-gray-300",
                          active
                            ? "border-primary-500 ring-2 ring-primary-500"
                            : "",
                          "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                        )
                      }
                    >
                      {({ checked, active }) => (
                        <>
                          <span className="flex flex-1">
                            <span className="flex flex-col">
                              <RadioGroup.Label
                                as="span"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {serviceClass.title}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className="mt-1 flex items-center text-sm text-gray-500"
                              >
                                {serviceClass.description}
                              </RadioGroup.Description>
                            </span>
                          </span>
                          <CheckCircleIcon
                            className={classNames(
                              !checked ? "invisible" : "",
                              "h-5 w-5 text-primary-600",
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              active ? "border" : "border-2",
                              checked
                                ? "border-primary-500"
                                : "border-transparent",
                              "pointer-events-none absolute -inset-px rounded-lg",
                            )}
                            aria-hidden="true"
                          />
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="pt-6">
          <span className="text-xs font-light">
            Energy Certification Information
          </span>
          <div className="grid grid-cols-6 gap-6 mt-2">
            <div className="col-span-2">
              <TextInput
                id="energyCertificateIssuerName"
                label="Energy Certification Issuer Name"
                type="text"
                placeholder="eg. Emmanuel Baidoo"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-2">
              <TextInput
                id="energyCertificateIssuerPhone"
                label="Energy Certification Issuer Phone Number"
                type="text"
                placeholder="eg. 0506339153"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-2">
              <TextInput
                id="energyCertificateIssuerId"
                label="Energy Certification Issuer ID"
                type="text"
                placeholder="eg. 55445-7444"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-2">
              <TextInput
                id="energyCertificateNumber"
                label="Energy Certification Number"
                type="text"
                placeholder="eg. GH034034034"
                required={true}
                {...form}
              />
            </div>
            <div className="col-span-4">
              <UploadArea
                id="energyCertificateDocumentUrl"
                label="Energy Commission Certificate"
                required={true}
                accept={{
                  "image/*": [".png", ".jpeg", ".jpg"],
                  "application/pdf": [".pdf"],
                }}
                {...form}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          className={classNames(
            true ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm",
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ServiceInfoForm;
