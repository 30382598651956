import { FC } from "react";
import { SearchSelectInput } from "components";
import { Configs } from "apollo/data";

interface ActivityPickerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

export const ActivityPicker: FC<ActivityPickerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  return (
    <SearchSelectInput
      id={id ?? "activity"}
      label={label ?? "Activity"}
      placeholder="Select Activity"
      options={Configs?.filter((el) => el?.type === "Activity")?.map(
        (activity: any) => ({
          label: {
            title: activity.name as string,
          },
          value: rawId ? activity?._id : activity,
        }),
      )}
      {...form}
      disabled={form.disabled}
    />
  );
};
