import ServiceInfoForm from "./service-info-form";
import PropertyInfoForm from "./property-info-form";
import ServiceInfoSummary from "./service-info-summary";
import PropertyInfoSummary from "./property-info-summary";
import RequestInfoForm from "./request-info-form";
import RequestInfoSummary from "./request-info-summary";
import { IdentityInfoForm, IdentityInfoFormSummary } from "../identity-info";
import { CustomerInfoForm, CustomerInfoFormSummary } from "../customer-info";

export const ServiceRequestFormSteps = [
  {
    name: "Request Info",
    description: "Information about the request.",
    accessor: "requestInfo",
    FormComponent: RequestInfoForm,
    SummaryComponent: RequestInfoSummary,
  },
  {
    name: "Customer Info",
    description: "Information about the customer making the request.",
    accessor: "customerInfo",
    FormComponent: CustomerInfoForm,
    SummaryComponent: CustomerInfoFormSummary,
  },
  {
    name: "Identity Info",
    description: "Information about the identity of the customer.",
    accessor: "identityInfo",
    FormComponent: IdentityInfoForm,
    SummaryComponent: IdentityInfoFormSummary,
  },
  {
    name: "Property Info",
    description: "Information about the facility / premise of the service.",
    accessor: "propertyInfo",
    FormComponent: PropertyInfoForm,
    SummaryComponent: PropertyInfoSummary,
  },
  {
    name: "Service Info",
    description: "Information about the service required by the customer.",
    accessor: "serviceInfo",
    FormComponent: ServiceInfoForm,
    SummaryComponent: ServiceInfoSummary,
  },
];

export default ServiceRequestFormSteps;
