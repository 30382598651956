import { gql, useMutation } from "@apollo/client";
import { LocationGenerics } from "router/location";
import { wrapClick } from "utils";
import { Modal } from "components";
// import { RejectServiceOrderForm } from "@ui/forms";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useSearch } from "react-location";

const REJECT_SERVICE_ORDER = gql`
  mutation RejectServiceRequest(
    $rejectServiceRequestId: ID!
    $reason: String!
  ) {
    rejectServiceRequest(id: $rejectServiceRequestId, reason: $reason) {
      _id
    }
  }
`;

export default function RejectServiceOrderContainer({
  open,
  close,
  refetch,
}: {
  open: boolean;
  close: () => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [rejectReplacementServiceOrder] = useMutation(REJECT_SERVICE_ORDER);

  const form = useFormik({
    initialValues: {
      reason: "",
    },
    onSubmit: async (values) => {
      await rejectReplacementServiceOrder({
        variables: {
          rejectServiceRequestId: searchParams.id,
          ...values,
        },
      }).then(({ data }) => {
        if (data.rejectServiceRequest._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order Rejected Successfully",
            }),
          );
          refetch?.();
          close();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not reject Service Order",
            }),
          );
        }
      });
    },
    onReset: () => {
      close();
    },
  });

  return (
    <Modal
      open={open}
      setOpen={close}
      title="Reject Service Order"
      size="4xl"
      description="Provide notes for rejecting service order"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={form.isSubmitting}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting
              ? "Rejecting Service Order..."
              : "Reject Service Order"}
          </button>
        </>
      )}
    >
      {/* <RejectServiceOrderForm
                form={form}
            /> */}
    </Modal>
  );
}
