import {
  CustomerType,
  CustomerTypes,
  Gender,
  Genders,
  Nationalities,
  Title,
  Titles,
} from "apollo/data";
import * as Yup from "yup";
import lodash from "lodash";
import moment from "moment";

export interface ICustomerInfoFormSchema {
  customerType: CustomerType;
  organization: {
    name: string;
    type: "Private" | "Public";
  };
  representative: {
    title: Title;
    fullName: string;
    nationality: string;
    dateOfBirth: string;
    gender: Gender;
    phoneNumber: string;
    emailAddress: string;
    profileImageUrl: string;
    hasGhanaCard: boolean;
    ghanaCardNumber: string;
    ghanaCardIssueDate: string;
    ghanaCardExpiryDate: string;
  };
}

const PersonalRequiredInfoSchema = Yup.object()
  .shape({
    title: Yup.string()
      .oneOf([...Titles])
      .required("Kindly select title"),
    fullName: Yup.string().required("Name is required"),
    nationality: Yup.string()
      .oneOf(lodash.map(Nationalities, "nationality"))
      .required("Kindly select nationality"),
    dateOfBirth: Yup.date()
      .max(
        moment().subtract(18, "years").toDate(),
        "Customer must be at least 18 years",
      )
      .required("Date of birth is required"),
    gender: Yup.string()
      .oneOf([...Genders])
      .required("Kindly select gender"),
    phoneNumber: Yup.string()
      .matches(
        /^0(2|5)(0|2|3|4|5|6|7|9)\d{7}$/,
        "Kindly enter a valid phone number",
      )
      .required("Phone number is required"),
    emailAddress: Yup.string()
      .email("Kindly enter a valid email address")
      .notRequired(),
    profileImageUrl: Yup.string().url().nullable().notRequired(),
  })
  .required();

export const CustomerInfoFormSchema = Yup.object().shape({
  customerType: Yup.string()
    .oneOf([...CustomerTypes])
    .required("Kindly select customer type"),
  representative: PersonalRequiredInfoSchema,
  organization: Yup.object().when("customerType", {
    is: "Organization",
    then: (schema) =>
      schema
        .shape({
          name: Yup.string().required("Organization name is required"),
        })
        .required(),
    otherwise: (schema) =>
      schema
        .shape({
          name: Yup.string().notRequired(),
        })
        .nullable()
        .notRequired(),
  }),
});
