import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetInspectionServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InspectionServiceOrderStatus
    $category: InspectionServiceOrderCategory
    $priority: InspectionServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $serviceClasses: [ServiceClass]
  ) {
    rows: getInspectionServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses
    ) {
      _id
      code
      serviceRequestCode
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
      }
      category
      status
      priority
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      disapprovedAt
      createdAt
      updatedAt
      result
    }
    count: getInspectionServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses
    )
  }
`;

export const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetInspectionServiceOrderSummary(
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $category: InspectionServiceOrderCategory
    $priority: InspectionServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $serviceClasses: [ServiceClass]
  ) {
    summary: getInspectionServiceOrderSummary(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      category: $category
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      serviceClasses: $serviceClasses
    ) {
      Pending
      Assigned
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;

export const GET_SERVICE_ORDER = gql`
  query GetInspectionServiceOrder($id: ID!) {
    serviceOrder: getInspectionServiceOrder(id: $id) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      disapprover {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      resolution {
        installationType {
          _id
          code
          name
        }
        property {
          ghanaPostAddress
          propertyImageUrls
          closestPoleNumber
          transformerPhase
          currentTransformerRatio
          meterHeight
          premiseType {
            _id
            code
            name
          }
          premiseCategory {
            _id
            code
            name
          }
          activity {
            _id
            code
            name
          }
          subActivity {
            _id
            code
            name
          }
          transformerNumber
          meterLocation
        }
        service {
          serviceType
          serviceClass
          tariffClass {
            _id
            code
            name
          }
          contractedDemand
          meterPhase
        }
        appliances {
          appliance {
            _id
            code
            name
            wattage
          }
          quantity
        }
        materials {
          material {
            _id
            code
            name
            quantityUnit
          }
          quantity
        }
        installationMaterials {
          material {
            _id
            name
          }
          unitCost
          quantity
          cost
        }
        installationCharges {
          title
          unitCost
          quantity
          cost
        }
        customerMaterials {
          material {
            _id
            name
          }
          unitCost
          quantity
          cost
        }
        notes
      }
      category
      status
      priority
      history {
        actor {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        assignee {
          ... on Contractor {
            _id
            name
            code
          }
          ... on ContractorUser {
            _id
            firstName
            lastName
            username
            code
          }
          ... on MeterContractor {
            _id
            name
            code
          }
          ... on MeterContractorUser {
            _id
            firstName
            lastName
            code
          }
          ... on User {
            _id
            firstName
            lastName
            code
          }
        }
        actorType
        assigneeType
        timestamp
        action
        notes
      }
      notes
      estimatedResolutionDate
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
      result
    }
  }
`;
