import { CustomerType, SelectInput, TextInput, UploadArea } from "../..";
import { useFormik } from "formik";
import { FC, useMemo } from "react";
import { IdentityInfoFormSchema } from "./schema";
import moment from "moment";
import { IIdentityInfoFormSchema } from "./schema";
import { classNames, wrapClick } from "utils";

export interface IdentityInfoFormProps {
  handleNext: (values: IIdentityInfoFormSchema) => void;
  handlePrevious: () => void;
  initialValues: IIdentityInfoFormSchema;
  values: {
    customerInfo: {
      customerType: CustomerType;
      organization: {
        type: string;
      };
      representative: {
        hasGhanaCard: boolean;
        ghanaCardNumber: string;
        ghanaCardIssueDate: string;
        ghanaCardExpiryDate: string;
      };
    };
  };
  handleCancel: () => void;
}

export const IdentityInfoForm: FC<IdentityInfoFormProps> = ({
  initialValues,
  values,
  handleNext,
  handlePrevious,
  handleCancel,
}) => {
  const form = useFormik<IIdentityInfoFormSchema>({
    initialValues: {
      ...initialValues,
      representative: {
        ...initialValues.representative,
        hasGhanaCard: values.customerInfo?.representative?.hasGhanaCard,
        ...(values.customerInfo?.representative?.ghanaCardNumber
          ? {
              ghanaCardNumber:
                values.customerInfo?.representative?.ghanaCardNumber,
            }
          : {}),
        ...(values.customerInfo?.representative?.ghanaCardIssueDate
          ? {
              ghanaCardIssueDate:
                values.customerInfo?.representative?.ghanaCardIssueDate,
            }
          : {}),
        ...(values.customerInfo?.representative?.ghanaCardExpiryDate
          ? {
              ghanaCardExpiryDate:
                values.customerInfo?.representative?.ghanaCardExpiryDate,
            }
          : {}),
      },
    },
    validationSchema: IdentityInfoFormSchema(
      values.customerInfo.customerType,
      values.customerInfo.organization.type === "Public",
    ),
    onSubmit: (values) => {
      handleNext(values);
    },
    onReset: () => {
      handleCancel?.();
    },
  });

  const formLabel = useMemo(
    () =>
      values.customerInfo.customerType === "Organization"
        ? "Representative"
        : "Customer",
    [values.customerInfo.customerType],
  );

  return (
    <form
      onSubmit={form.handleSubmit}
      className="flex-1 flex flex-col overflow-hidden"
    >
      <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
        {values.customerInfo.customerType === "Organization" && (
          <div className="">
            <span className="text-xs font-light">
              Organization Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="organization.taxIdentificationNumber"
                  label="Tax Identification Number"
                  type="text"
                  placeholder="eg. C0012345678"
                  maxLength={11}
                  required={values.customerInfo.organization.type !== "Public"}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="organization.organizationRegistrationNumber"
                  label="Organization Registration Number"
                  type="text"
                  placeholder="eg. CS012345678"
                  maxLength={11}
                  required={values.customerInfo.organization.type !== "Public"}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="organization.organizationRegistrationDate"
                  label="Organization Registration Date"
                  type="date"
                  max={moment().format("YYYY-MM-DD")}
                  required={values.customerInfo.organization.type !== "Public"}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="organization.certificateOfIncorporationDocumentUrl"
                  label="Certificate of Incorporation"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  required={values.customerInfo.organization.type !== "Public"}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="organization.organizationRegistrationDocumentUrl"
                  label="Business Registration Document"
                  accept={{
                    "image/*": [".png", ".jpeg", ".jpg"],
                    "application/pdf": [".pdf"],
                  }}
                  required={values.customerInfo.organization.type !== "Public"}
                  {...form}
                />
              </div>
            </div>
          </div>
        )}
        {!form.values.representative.hasGhanaCard && (
          <div
            className={classNames(
              values.customerInfo.customerType === "Organization" ? "pt-6" : "",
            )}
          >
            <span className="text-xs font-light">
              Identity Type Information
            </span>
            <div className="grid grid-cols-3 gap-6 mt-2">
              <div>
                <SelectInput
                  id="representative.identityCardType"
                  label="ID Type"
                  options={[
                    { label: "--- Select ID Type ---", value: "" },
                    { label: "Driver's License", value: "DriversLicense" },
                    { label: "Passport", value: "Passport" },
                    {
                      label: "Voters ID",
                      value: "VotersIdentificationCard",
                    },
                  ]}
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        )}
        {form.values.representative.hasGhanaCard ? (
          <div
            className={classNames(
              values.customerInfo.customerType === "Organization" ? "pt-6" : "",
            )}
          >
            <span className="text-xs font-light">
              {formLabel} Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardNumber"
                  label="Ghana Card Number"
                  type="text"
                  placeholder="eg. GHA-123456789-0"
                  minLength={15}
                  maxLength={15}
                  required={true}
                  disabled={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardIssueDate"
                  label="Ghana Card Issue Date"
                  type="date"
                  required={true}
                  // disabled={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.ghanaCardExpiryDate"
                  label="Ghana Card Expiry Date"
                  type="date"
                  required={true}
                  // disabled={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="representative.ghanaCardFrontImageUrl"
                  label="Ghana Card Front Image"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-3">
                <UploadArea
                  id="representative.ghanaCardBackImageUrl"
                  label="Ghana Card Back Image"
                  required={true}
                  {...form}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-6">
            <span className="text-xs font-light">
              {formLabel} Identity Information
            </span>
            <div className="grid grid-cols-6 gap-6 mt-2">
              <div className="col-span-2">
                <TextInput
                  id="representative.identityCardNumber"
                  label={"ID Number"}
                  type="text"
                  placeholder="eg. GH034034034"
                  required={true}
                  {...form}
                />
              </div>

              <div className="col-span-2">
                <TextInput
                  id="representative.identityCardIssueDate"
                  label="ID Issue Date"
                  type="date"
                  required={true}
                  {...form}
                />
              </div>

              {form.values.representative?.identityCardType !==
                "VotersIdentificationCard" && (
                <div className="col-span-2">
                  <TextInput
                    id="representative.identityCardExpiryDate"
                    label="ID Expiry Date"
                    type="date"
                    required={true}
                    {...form}
                  />
                </div>
              )}

              <div className="col-span-3 col-start-1">
                <UploadArea
                  id="representative.identityCardFrontImageUrl"
                  label="ID Front Image"
                  required={true}
                  {...form}
                />
              </div>

              {form.values.representative?.identityCardType ===
                "DriversLicense" && (
                <div className="col-span-3">
                  <UploadArea
                    id="representative.identityCardBackImageUrl"
                    label="ID Back Image"
                    required={true}
                    {...form}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
        <button
          type="button"
          onClick={wrapClick(form.handleSubmit)}
          // disabled={!form.isValid}
          className={classNames(
            true ? "hover:bg-primary-700" : "cursor-not-allowed",
            "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm",
          )}
        >
          Next
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(handlePrevious)}
        >
          Previous
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={wrapClick(form.resetForm)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default IdentityInfoForm;
