import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { FC } from "react";
import QRCode from "react-qr-code";
import {AppliancesInfoCard, MaterialsInfoCard, PropertyInfoCard, RequestedServiceInfoCard} from "../";

interface InspectionServiceOrderResolutionDetailsProps {
  region: {
    _id: string;
    code: string;
    name: string;
  };
  district: {
    _id: string;
    code: string;
    name: string;
  };
  resolution: {
    property: any;
    service: any;
    appliances: any;
    materials: any;
    customerMaterials: any;
    installationMaterials: any;
    installationCharges: any;
    installationType: any;
    notes: string;
  };
  result: string;
}

export const InspectionServiceOrderResolutionDetails: FC<
  InspectionServiceOrderResolutionDetailsProps
> = ({ resolution, region, district, result }) => {
  const currentConfig = useReactiveVar(currentConfigVar);

  return (
    <div className="relative">
      <img
        src={
          result === "Passed"
            ? "/passed-stamp.png"
            : "/failed-stamp.png"
        }
        className="h-24 absolute right-8 top-8"
        alt=""
      />
      <div className="space-y-6 divide-y divide-gray-200">
        <div className="">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Property Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about property
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4">
            <PropertyInfoCard
              data={{
                region,
                district,
                ...resolution?.property,
              }}
              config={currentConfig}
            />
          </div>
        </div>
        <div className="pt-6">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Service Information
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about property
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4">
            <RequestedServiceInfoCard data={resolution} />
          </div>
        </div>
        <div className="pt-6">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Materials & Appliances Infomation
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about property
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4 flex flex-col gap-6">
            <MaterialsInfoCard
              title="Materials Information"
              description="Materials Required For Installation"
              data={[
                ...(resolution?.materials ?? [] ),
                ...(resolution?.customerMaterials ?? []),
                ...(resolution?.installationMaterials ?? [])
              ]
              }
            />
            {
              !!resolution?.installationCharges?.length && 
                <MaterialsInfoCard
                  title="Supplementary Charges Information"
                  description="Supplementary Items Required For Installation"
                  data={resolution?.installationCharges?.map((charge: any) => ({
                      material: {
                        name: charge.title,
                      },
                      quantity: charge.quantity
                  }))
                  }
                />
            }
            <AppliancesInfoCard
              title="Appliances Information"
              description="Appliances Found At Customers Premise"
              data={resolution?.appliances}
            />
          </div>
        </div>
        <div className="pt-6">
          <div className="flex justify-between items-center cursor-pointer">
            <div>
              <h3 className="text-md leading-4 font-normal text-gray-900 dark:text-gray-100">
                Extra Infomation
              </h3>
              <p className="mt-1 text-xs text-gray-500">
                Details about property
              </p>
            </div>
            <div />
          </div>
          <div className="mt-4">
            <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <span className="block text-sm font-light text-gray-700">
                    Pole Number
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {resolution?.property?.poleNumber || "N/A"}
                  </div>
                </div>
                <div className="col-span-2">
                  <span className="block text-sm font-light text-gray-700">
                    Notes
                  </span>
                  <div className="mt-1 block w-full sm:text-sm">
                    {resolution?.notes || "N/A"}
                  </div>
                  <div className="mt-1 block w-full sm:text-sm">
                    <QRCode
                      value={resolution?.property?.poleNumber || ""}
                      size={28}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      viewBox={`0 0 28 28`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
