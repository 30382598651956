import { useMutation, useQuery } from "@apollo/client";
import { wrapClick } from "utils";
import { useFormik } from "formik";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useSearch } from "react-location";
import * as Yup from "yup";
import { GET_SERVICE_REQUEST, UPDATE_SERVICE_REQUEST } from "./apollo";
import { LocationGenerics } from "router/location";
import { Modal, UpdateServiceRequestCustomerForm } from "components";

export default function UpdateServiceRequestContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_SERVICE_REQUEST, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });
  const [updateServiceRequest] = useMutation(UPDATE_SERVICE_REQUEST, {
    refetchQueries: ["GetServiceRequest"],
  });

  const form = useFormik<{
    phoneNumber: string;
    fullName: String;
    serviceType: string;
    serviceClass: string;
    region: string;
    district: string;
  }>({
    initialValues: {
      phoneNumber: "",
      fullName: "",
      serviceClass: "",
      serviceType: "",
      district: "",
      region: "",
    },
    validationSchema: Yup.object().shape({
      phoneNumber: Yup.string()
        .min(10, "Phone number must be at least 10 digits")
        .required("Phone Number is Required"),
      fullName: Yup.string().required("Full Name is Required"),
      serviceType: Yup.string()
        .oneOf(["Postpaid", "Prepaid"])
        .required("Service Type is Required"),
      serviceClass: Yup.string()
        .oneOf(["Residential", "NonResidential", "Industrial"])
        .required("Service Class is Required"),
    }),
    onSubmit: async (values) => {
      await updateServiceRequest({
        variables: {
          ...values,
          id: data?.serviceRequest?._id,
        },
      }).then(({ data }) => {
        if (data.updateServiceRequest._id) {
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Request Edited Successfully",
            })
          );
          refetch?.();
          form.resetForm();
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not edit Service Request",
            })
          );
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (data?.serviceRequest) {
      // alert("Hello" + data?.customer?.customer?.fullName)
      form.setValues({
        fullName:
          data?.serviceRequest?.request?.customer?.customerType === "Individual"
            ? data?.serviceRequest?.request?.customer?.representative?.fullName
            : data?.serviceRequest?.request?.customer?.organization?.name ||
              "N/A",
        phoneNumber:
          data?.serviceRequest?.request?.customer?.customerType === "Individual"
            ? data?.serviceRequest?.request?.customer?.representative
                ?.phoneNumber || "N/A"
            : data?.serviceRequest?.request?.customer?.organization
                ?.phoneNumber || "N/A",
        serviceType:
          data?.serviceRequest?.request?.service?.serviceType ?? "N/A",
        serviceClass:
          data?.serviceRequest?.request?.service?.serviceClass ?? "N/A",
        region: data?.serviceRequest?.region?._id,
        district: data?.serviceRequest?.district?._id,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.serviceRequest, searchParams.id]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={loading}
      size='4xl'
      title='Edit Service Request Information'
      description='Provide the details to update service request information'
      renderActions={() => (
        <>
          {/* {withPermissions([  "customers:update"])( */}
          <button
            type='button'
            disabled={form.isSubmitting || !form.isValid}
            className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
            onClick={wrapClick(form.handleSubmit)}
          >
            {form.isSubmitting ? "Editing ..." : "Edit"}
          </button>
          {/* )} */}
        </>
      )}
    >
      {data?.serviceRequest?._id && (
        <UpdateServiceRequestCustomerForm
          form={form}
          customer={{
            ...data?.serviceRequest?.request,
            status: data?.serviceRequest?.status,
            code: data?.serviceRequest?.code,
          }}
        />
      )}
    </Modal>
  );
}
