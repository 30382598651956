import { FC } from "react";
import { SearchSelectInput } from "components";
import { Configs } from "apollo/data";
import lodash from "lodash";

interface PremiseCategoryPickerProps {
  filter?: {
    premiseType?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

export const PremiseCategoryPicker: FC<PremiseCategoryPickerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const items = Configs?.filter((el) => lodash.has(el, "premiseType"))
    ?.filter(
      (el) =>
        el?.type === "PremiseCategory" &&
        el?.premiseType === filter?.premiseType,
    )
    ?.map((premiseCategory: any) => ({
      label: {
        title: premiseCategory.name as string,
      },
      value: rawId ? premiseCategory._id : premiseCategory,
    }));

  return (
    <SearchSelectInput
      id={id ?? "premiseCategory"}
      label={label ?? "Premise Category"}
      placeholder="Select Premise Category"
      options={items}
      {...form}
      disabled={form.disabled}
    />
  );
};
