import { FC } from "react";
import { ICreateServiceRequestFormSchema } from "./schema";
import moment from "moment";
import lodash from "lodash";
import { ICurrentConfig } from "apollo/cache/config";

function quantityText(quantity: number, isDisplay = false) {
  let newQuantity = quantity;
  if (isDisplay) {
    newQuantity = quantity - 1;
  }
  if (!newQuantity) return "with no additional meter";
  switch (newQuantity) {
    case 0: {
      return "with no additional meter";
    }
    case 1: {
      return "with 1 additional meter";
    }
    default: {
      return `with ${newQuantity} additional meters`;
    }
  }
}

const RequestInfoSummary: FC<{
  data: ICreateServiceRequestFormSchema["requestInfo"];
  config: ICurrentConfig;
  isDisplay?: boolean;
}> = ({ data, isDisplay, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
      <span className="text-xs font-light">Request Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Request Category
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.category || "N/A"}
          </div>
        </div>
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            Customer Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {lodash.startCase(data?.type) || "N/A"}
          </div>
        </div>
        {data?.type === "NewServiceRequest" && (
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Is Existing Customer?
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {data?.isExistingCustomer ? "Yes" : "No"}
            </div>
          </div>
        )}
        {data?.type === "NewServiceRequest" && (
          <div className="col-span-1">
            <span className="block text-sm font-light text-gray-700">
              Number of Meters
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              1 mother meter {quantityText(data.quantity, isDisplay)}
            </div>
          </div>
        )}
      </div>
    </div>
    {data?.existingServicePoint && (
      <div className="pt-6">
        <span className="text-xs font-light">
          Existing Customer Information
        </span>

        <div className="mt-2 border border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
          <div className="grid grid-cols-4 gap-4 p-4">
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Type
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.customerType || "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Title
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.representative?.title ||
                  data?.existingServicePoint?.customer?.representative?.title ||
                  "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Full Name
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.representative
                  ?.fullName ||
                  data?.existingServicePoint?.customer?.representative
                    ?.fullName ||
                  "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Nationality
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.representative
                  ?.nationality ||
                  data?.existingServicePoint?.customer?.representative
                    ?.nationality ||
                  "N/A"}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Date of Birth
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {moment(
                  data?.existingServicePoint?.customer?.representative
                    ?.dateOfBirth ||
                    data?.existingServicePoint?.customer?.representative
                      ?.dateOfBirth,
                ).format(dateFormat)}
              </div>
            </div>
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Gender
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.representative?.gender ||
                  data?.existingServicePoint?.customer?.representative
                    ?.gender ||
                  "N/A"}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4 p-4">
            <div className="">
              <span className="block text-sm font-light text-gray-700">
                Account Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.account?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Customer Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.customer?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Property Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.property?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Service Point Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.code || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Geographical Code
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.geoCode || "N/A"}
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Meter Number
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.meter?.code || "N/A"}
              </div>
            </div>
            <div className="col-span-2">
              <span className="block text-sm font-light text-gray-700">
                Property Address
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {data?.existingServicePoint?.meter?.code || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

export default RequestInfoSummary;
