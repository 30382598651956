import { FC } from "react";
import { SearchSelectInput } from "components";
import { Configs } from "apollo/data";
import lodash from "lodash";

interface SubActivityPickerProps {
  filter?: {
    activity?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

export const SubActivityPicker: FC<SubActivityPickerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const items = Configs?.filter((el) => lodash.has(el, "activity"))
    ?.filter(
      (el) => el?.type === "SubActivity" && el?.activity === filter?.activity,
    )
    ?.map((subActivity: any) => ({
      label: {
        title: subActivity.name as string,
      },
      value: rawId ? subActivity._id : subActivity,
    }));

  return (
    <SearchSelectInput
      id={id ?? "subActivity"}
      label={label ?? "Sub Activity"}
      placeholder="Select Sub Activity"
      options={items}
      {...form}
      disabled={form.disabled}
    />
  );
};
