import { FC } from "react";
import lodash from "lodash";

const RequestedServiceInfoCard: FC<{ data: any }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
      <span className="text-xs font-light">Service Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Service Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.service?.serviceType || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Service Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.service?.serviceClass
              ? lodash.startCase(data?.service?.serviceClass)
              : "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Installation Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.installationType?.name || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Demand Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Tariff Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {lodash.startCase(data?.service?.tariffClass?.name) || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Contracted Demand
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.service?.contractedDemand || "N/A"} KVh
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Load Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.service?.meterPhase || "N/A"} Phase
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default RequestedServiceInfoCard;